import { Navigate, useLoaderData } from 'react-router-dom'

export const loader = async ({ params, request }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('token')
  const redirect = urlParams.get('redirect') || '/'

  // Store the token in localstorage both for use in the following API call and
  // to use it for future API communication.
  localStorage.setItem('token', token)

  return { redirect }
}

export default function AuthenticatePage() {
  const { redirect } = useLoaderData()

  return <Navigate to={redirect} replace />
}
