import Config from '../Config'

const BASE_URL = `${Config.API}/payment`

export const payment = async (type: string, id: number) => {
  const response = await fetch(`${BASE_URL}/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ type, id })
  })

  return await response.json()
}
