import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Sheet } from '../../../../lib/Types'
import * as SheetAPI from '../API/SheetAPI'
import { SheetType } from '../Enums/SheetType'

type UpdateSheetProps = {
  onSuccess?: (sheet: Sheet) => void
}

export const useUpdateSheet = ({ onSuccess }: UpdateSheetProps = {}) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sheet: Sheet) => SheetAPI.saveSheet(sheet),
    onMutate: (newSheet: Sheet): Sheet => {
      const queryKey = newSheet.sheet_type === SheetType.BUDGET
        ? ['budgets', newSheet.id]
        : ['accountings', newSheet.id]

      queryClient.cancelQueries({ queryKey })

      const previousSheet = queryClient.getQueryData(queryKey) as Sheet

      queryClient.setQueryData(queryKey, newSheet)

      return previousSheet
    },
    onError: (err, newSheet, previousSheet: Sheet | undefined) => {
      if (previousSheet) {
        const queryKey = previousSheet.sheet_type === SheetType.BUDGET
          ? ['budgets', previousSheet.id]
          : ['accountings', previousSheet.id]

        queryClient.setQueryData(queryKey, previousSheet)
      }

      console.error(err)
    },
    onSuccess: (data, newSheet: Sheet) => {
      if (onSuccess) {
        onSuccess(newSheet)
      }
    }
  })
}

export const useInviteUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sheet: Sheet) => SheetAPI.inviteUser(sheet),
    onMutate: (newSheet: Sheet): Sheet => {
      const queryKey = newSheet.sheet_type === SheetType.BUDGET
        ? ['budgets', newSheet.id]
        : ['accountings', newSheet.id]

      queryClient.cancelQueries({ queryKey })

      const previousSheet = queryClient.getQueryData(queryKey) as Sheet

      queryClient.setQueryData(queryKey, newSheet)

      return previousSheet
    },
    onError: (err, newSheet, previousSheet: Sheet | undefined) => {
      if (previousSheet) {
        const queryKey = previousSheet.sheet_type === SheetType.BUDGET
          ? ['budgets', previousSheet.id]
          : ['accountings', previousSheet.id]

        queryClient.setQueryData(queryKey, previousSheet)
      }

      console.error(err)
    }
  })
}

export const useDeleteSheet = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (sheet: Sheet) => SheetAPI.deleteSheet(sheet),
    onSuccess: (data, sheet: Sheet) => {
      if (sheet.sheet_type === SheetType.BUDGET) {
        queryClient.setQueryData(
          ['budgets'],
          (current: Sheet[]) => [...current.filter(budget => budget.id !== sheet.id)]
        )
      } else if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({ queryKey: ['accountings'] })
      }
    }
  })
}
