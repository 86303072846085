import Config from '../../../../lib/Config'
import { Sheet, SheetCategory, SheetCategoryCreate } from '../../../../lib/Types'

export const addCategory = async (sheet: Sheet, category: SheetCategoryCreate): Promise<SheetCategory> => {
  const response = await fetch(
    `${Config.API}/${sheet.sheet_type}/${sheet.id}/categories/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(category)
    }
  )

  return response.json()
}

export const saveCategory = (category: Pick<SheetCategory, 'id' | 'name' | 'budget'>) => fetch(
  `${Config.API}/categories/${category.id}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(category)
  }
)

export const deleteCategory = (category: SheetCategory) => fetch(
  `${Config.API}/categories/${category.id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }
)

export const getCategories = async (sheet: Sheet) => {
  const response = await fetch(`${Config.API}/${sheet.sheet_type}/${sheet.id}/categories/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })
  return response.json()
}
