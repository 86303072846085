import React, { FC, useContext, useEffect, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { sum, sumForUser } from "../../../lib/Calculator";
import { SpendContext } from "../../../lib/Context";
import { formatCurrency } from "../../../lib/CurrencyFormatter";
import { UserType } from "../../../lib/Enums/UserType";
import { generateIdForItem } from "../../../lib/IdGenerator";
import { SheetCategory, Spend } from "../../../lib/Types";
import { SpendFrequency } from "../lib/Enums/SpendFrequency";
import { useBudget } from "../lib/Hooks/Budget";
import SpendComponent from "./SpendComponent";

interface Props {
  category: SheetCategory;
  spends: Spend[];
}

const Items: FC<Props> = ({ category, spends }) => {
  const [items, setItems] = useState(spends);
  const { data: budget } = useBudget();
  const { saveOrder } = useContext(SpendContext);

  useEffect(() => {
    setItems(spends);
  }, [spends]);

  return (
    <>
      <table
        className={`min-w-full table-budget ${budget!.user ? "col-5" : ""}`}
      >
        <tbody>
          <tr className="bg-gray-100 border-b border-x border-gray-300">
            <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
              {category.name}
            </th>
            <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
              {formatCurrency(sum(items, SpendFrequency.YEARLY))}
            </th>
            <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
              {formatCurrency(sum(items, SpendFrequency.MONTHLY))}
            </th>
            {budget!.user && (
              <>
                <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  {formatCurrency(sumForUser(items, UserType.PRIMARY))}
                </th>
                <th className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  {formatCurrency(sumForUser(items, UserType.SECONDARY))}
                </th>
              </>
            )}
          </tr>
        </tbody>
      </table>
      <table
        className={`min-w-full table-budget ${budget!.user ? "col-5" : ""}`}
      >
        <ReactSortable
          tag="tbody"
          group={`category-${category.id}`}
          list={items}
          delay={3000}
          delayOnTouchOnly={true}
          setList={(reorderedItems, _, { dragging }) => {
            if (!dragging) {
              return;
            }

            saveOrder(reorderedItems);
            setItems(reorderedItems);
          }}
          dragClass="list-selected"
          ghostClass="list-placeholder"
          draggable=".draggable"
        >
          {items.map((spend) => (
            <SpendComponent key={generateIdForItem(spend)} spend={spend} />
          ))}
        </ReactSortable>
      </table>
    </>
  );
};

export default Items;
