import React, { useState } from 'react'
import Headline from '../../Elements/Headline'
import { Sheet } from '../../../lib/Types'
import Categories from './Categories'
import { SheetContext } from '../../../lib/Context'
import Share from './Share'
import Deletion from './Deletion'
import Base from './Base'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import { SheetType } from '../lib/Enums/SheetType'
import { useBudget } from '../lib/Hooks/Budget'
import { useAccounting } from '../lib/Hooks/Accounting'

type Props = {
  sheet: Sheet
  children?: React.ReactNode
}

const useBudgetOrAccounting = (sheetType: SheetType) => {
  if (sheetType === SheetType.BUDGET) {
    return useBudget()
  } else if (sheetType === SheetType.ACCOUNTING) {
    return useAccounting()
  } else {
    throw new Error(`Invalid sheet type: ${sheetType}`)
  }
}

const Configuration = ({ sheet, children }: Props) => {
  const [isExpanded, toggleExpanded] = useState(false)

  const { data } = useBudgetOrAccounting(sheet.sheet_type)

  return (
    <>
      <Headline className='mb-5'>Indstillinger</Headline>
      <SheetContext.Provider
        value={{ sheet: data }}
      >
        <Base />
        {children && (
          <>
            <Headline className='mb-2'>
              <div className='flex items-center cursor-pointer' onClick={() => toggleExpanded(!isExpanded)}>
                {!isExpanded && <MdArrowRight size='1.25rem' />}
                {isExpanded && <MdArrowDropDown size='1.25rem' />}
                {sheet.sheet_type === SheetType.ACCOUNTING ? 'Regnskab' : 'Budget'}
              </div>
            </Headline>
            {isExpanded && (
              <div className='ml-5'>{children}</div>
            )}
          </>
        )}
        <Categories />
        <Share />
        <Deletion />
      </SheetContext.Provider>
    </>
  )
}

export default Configuration
