import React, { FC } from 'react'
import { createPortal } from 'react-dom'

interface Props {
  children: React.ReactNode
  show: boolean
  onHide: () => void
}

export const Modal: FC<Props> = ({ children, show, onHide }) => {
  if (!show) {
    return null
  }

  return createPortal(
    <div className='fixed w-full h-screen left-0 top-16'>
      <div className='inset-0 z-30 w-full h-screen overflow-y-auto'>
        <div className='absolute inset-0 w-full h-full bg-gray-500 opacity-75'></div>
        <div className='flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0'>
          <span
            className='hidden sm:inline-block sm:align-middle sm:h-screen'
            aria-hidden='true'
          ></span>
          <div
            className='relative w-full inline-block overflow-hidden transition-all transform sm:max-w-lg'
            role='dialog'
            aria-modal='true'
            aria-labelledby='modal-headline'
          >
            <div>
              <div className='rounded-lg p-8 bg-white shadow'>
                <div className='absolute right-4 top-4'>
                  <button
                    className='bg-transparent border border-transparent'
                    onClick={onHide}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='currentColor'
                      className='w-6 h-6 text-gray-700'
                      viewBox='0 0 1792 1792'
                    >
                      <path d='M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z'></path>
                    </svg>
                  </button>
                </div>
                <div className='bg-white text-left'>{children}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>,
    document.body,
  )
}
