import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Sheet, SheetCategory, SheetCategoryCreate } from '../../../../lib/Types'
import { addCategory, deleteCategory, getCategories, saveCategory } from '../API/CategoriesAPI'

const getQueryKey = (sheet: Sheet) => [sheet.sheet_type, sheet.id, 'categories']

export const useCreateCategory = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (newCategory: SheetCategoryCreate) => addCategory(sheet, newCategory),
    onSuccess: (newCategory: SheetCategory) => {
      queryClient.setQueryData(
        getQueryKey(sheet),
        (current: SheetCategory[]) => [...current, newCategory]
      )
    }
  })
}

export const useUpdateCategory = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (category: Pick<SheetCategory, 'id' | 'name' | 'budget'>) => saveCategory(category),
    onMutate: (categoryToUpdate: SheetCategory): SheetCategory[] => {
      const queryKey = getQueryKey(sheet)

      queryClient.cancelQueries({ queryKey })

      const previousState = queryClient.getQueryData(queryKey) as SheetCategory[]

      const newCategories = previousState.map((category) => {
        return category.id === categoryToUpdate.id
          ? categoryToUpdate
          : category
      }).sort((a, b) => a.order - b.order)

      queryClient.setQueryData(queryKey, newCategories)

      return previousState
    },
    onError: (err, categoryToUpdate: SheetCategory, previousState: SheetCategory[] | undefined) => {
      if (previousState) {
        const queryKey = getQueryKey(sheet)

        queryClient.setQueryData(queryKey, previousState)
      }

      console.error(err)
    }
  })
}

export const useDeleteCategory = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (categoryToDelete: SheetCategory) => deleteCategory(categoryToDelete),
    onMutate: (categoryToDelete: SheetCategory): SheetCategory[] => {
      const queryKey = getQueryKey(sheet)

      queryClient.cancelQueries({ queryKey })

      const previousState = queryClient.getQueryData(queryKey) as SheetCategory[]

      queryClient.setQueryData(queryKey, previousState.filter((category) => category.id !== categoryToDelete.id))

      return previousState
    },
    onError: (err, categoryToDelete: SheetCategory, previousState: SheetCategory[] | undefined) => {
      if (previousState) {
        const queryKey = getQueryKey(sheet)

        queryClient.setQueryData(queryKey, previousState)
      }

      console.error(err)
    }
  })
}

export const useCategories = (sheet: Sheet) => {
  return useQuery<SheetCategory[], Error>({
    queryKey: getQueryKey(sheet),
    queryFn: () => getCategories(sheet),
    staleTime: Infinity
  })
}
