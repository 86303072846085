import React, { useContext } from 'react'
import { Form, Formik } from 'formik'
import Input from '../../Forms/Input'
import Button from '../../Forms/Button'
import { SheetContext } from '../../../lib/Context'
import Textarea from '../../Forms/Textarea'
import { getImperativName } from '../Configuration/lib/Naming'
import { useUpdateSheet } from '../lib/Hooks/Sheet'

const Base = () => {
  const { sheet } = useContext(SheetContext)
  const { mutate: saveSheet, isPending } = useUpdateSheet()

  return (
    <div className='my-5'>
      <Formik
        initialValues={{
          name: sheet.name || '',
          description: sheet.description || ''
        }}
        onSubmit={async ({ name, description }) => {
          const newSheet = structuredClone(sheet)
          newSheet.name = name
          newSheet.description = description

          saveSheet(newSheet)
        }}

      >
        <Form>
          <p className='mb-2'>Navn</p>
          <Input name='name' placeholder={`Angiv navnet på ${getImperativName(sheet.sheet_type)}`} className='mb-2' />
          <p className='mb-2'>Beskrivelse</p>
          <Textarea name='description' placeholder={`Angiv en beskrivelse af ${getImperativName(sheet.sheet_type)}`} className='mb-2' />
          <Button
            type='submit'
            className='w-full'
            loading={isPending}
          >
            Gem
          </Button>
        </Form>
      </Formik>
    </div>
  )
}

export default Base
