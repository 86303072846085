import { SpendFrequency } from '../Components/Sheets/lib/Enums/SpendFrequency'
import { UserType } from './Enums/UserType'
import { Spend, SpendAmount } from './Types'

/**
 * Build an array of items to sum by including items children and excluding items with children.
 */
const getItemsToSum = (spends: Spend[]): Spend[] => {
  const spendsToSum = {} as { [k: string]: Spend }

  const parentIds = [] as number[]
  const childrenToSum = [] as Spend[]

  spends.forEach((spend) => {
    // If a spend has children, add all children to the spendsToSum but not the
    // item itself.
    if (spend.children !== undefined && spend.children.length) {
      spend.children.forEach((child) => {
        childrenToSum.push(child)
      })
      // Some spends exists that have a parent ID, but their parent has been
      // deleted. These should not be included in the calculation. Therefore,
      // build a list of parent IDs to use later for excluded orphan children.
      parentIds.push(spend.id)
    } else if (spend.parent_id) {
      childrenToSum.push(spend)
    } else {
      spendsToSum[spend.id] = spend
    }
  })

  childrenToSum.forEach((child) => {
    // Only indclude children that have an existing parent.
    if (child.parent_id && parentIds.indexOf(child.parent_id) !== -1) {
      spendsToSum[child.id] = child
    }
  })

  return Object.values(spendsToSum)
}

/**
 * Calculate the total sum for a set of items for a given period
 */
export const sum = (
  items: Spend[],
  period: SpendFrequency.MONTHLY | SpendFrequency.YEARLY,
): number => {
  let amount = 0

  getItemsToSum(items).forEach((item) => {
    const sumForItem = getItemAmountForPeriod(item, period)
    amount += sumForItem
  })

  return amount
}

/**
 * Calculate the total sum for a specific user
 */
export const sumForUser = (items: Spend[], user: UserType): number => {
  let amount = 0

  getItemsToSum(items).forEach((item) => {
    const amountForItem = getItemAmountForPeriod(item, SpendFrequency.MONTHLY)
    const percentageForUser =
      user === UserType.PRIMARY
        ? item.percentage_primary_user
        : item.percentage_secondary_user

    amount += (amountForItem / 100) * percentageForUser
  })

  return amount
}

export const getItemAmountForPeriod = (
  spend: Spend,
  period: SpendFrequency,
): number => {
  return convertAmountToPeriod(spend.amounts.at(-1)!, period)
}

export const convertAmountToPeriod = (
  amount: SpendAmount,
  targetFrequency: SpendFrequency,
): number => {
  let { amount: currentAmount, frequency: currentFrequency } = amount

  switch (targetFrequency) {
    case SpendFrequency.MONTHLY: {
      if (currentFrequency === SpendFrequency.BIMONTHLY) {
        currentAmount = currentAmount / 2
      } else if (currentFrequency === SpendFrequency.QUARTERLY) {
        currentAmount = currentAmount / 3
      } else if (currentFrequency === SpendFrequency.BIYEARLY) {
        currentAmount = currentAmount / 6
      } else if (currentFrequency === SpendFrequency.YEARLY) {
        currentAmount = currentAmount / 12
      }
      break
    }
    case SpendFrequency.BIMONTHLY: {
      if (currentFrequency === SpendFrequency.MONTHLY) {
        currentAmount = currentAmount * 2
      } else if (currentFrequency === SpendFrequency.QUARTERLY) {
        currentAmount = (currentAmount * 2) / 3
      } else if (currentFrequency === SpendFrequency.BIYEARLY) {
        currentAmount = currentAmount / 3
      } else if (currentFrequency === SpendFrequency.YEARLY) {
        currentAmount = currentAmount / 6
      }
      break
    }
    case SpendFrequency.QUARTERLY: {
      if (currentFrequency === SpendFrequency.MONTHLY) {
        currentAmount = currentAmount * 3
      } else if (currentFrequency === SpendFrequency.BIMONTHLY) {
        currentAmount = (currentAmount * 3) / 2
      } else if (currentFrequency === SpendFrequency.BIYEARLY) {
        currentAmount = currentAmount / 2
      } else if (currentFrequency === SpendFrequency.YEARLY) {
        currentAmount = currentAmount / 4
      }
      break
    }
    case SpendFrequency.BIYEARLY: {
      if (currentFrequency === SpendFrequency.MONTHLY) {
        currentAmount = currentAmount * 6
      } else if (currentFrequency === SpendFrequency.BIMONTHLY) {
        currentAmount = currentAmount * 3
      } else if (currentFrequency === SpendFrequency.QUARTERLY) {
        currentAmount = currentAmount * 2
      } else if (currentFrequency === SpendFrequency.YEARLY) {
        currentAmount = currentAmount / 2
      }
      break
    }
    case SpendFrequency.YEARLY: {
      if (currentFrequency === SpendFrequency.MONTHLY) {
        currentAmount = currentAmount * 12
      } else if (currentFrequency === SpendFrequency.BIMONTHLY) {
        currentAmount = currentAmount * 6
      } else if (currentFrequency === SpendFrequency.QUARTERLY) {
        currentAmount = currentAmount * 4
      } else if (currentFrequency === SpendFrequency.BIYEARLY) {
        currentAmount = currentAmount * 2
      }
      break
    }
  }

  return parseFloat(currentAmount.toFixed(2))
}

export const allowanceForUser = (
  items: Spend[],
  userType: UserType,
  salary: number,
): number => {
  const totalSpend = sumForUser(items, userType)
  return salary ? salary - totalSpend : 0
}
