import { FC } from 'react'
import { allowanceForUser } from '../../../../lib/Calculator'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import { UserType } from '../../../../lib/Enums/UserType'
import { useBudget } from '../../lib/Hooks/Budget'
import { useSpends } from '../../lib/Hooks/Spends'

type Props = {
  userType: UserType
}
const AllowanceColumn: FC<Props> = ({ userType }) => {
  const { data: budget } = useBudget()
  const { data: items } = useSpends(budget!)

  const salary =
    userType === UserType.PRIMARY
      ? budget!.author.data.salary!
      : budget!.user!.data.salary!

  const allowanceForAuthor = allowanceForUser(items!, userType, salary)

  return <span>{formatCurrency(allowanceForAuthor)}</span>
}

export default AllowanceColumn
