import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { Accounting, Sheet } from '../../../../lib/Types'
import * as API from '../API/AccountingAPI'

export const useCreateAccounting = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (accounting: Partial<Accounting>) =>
      API.createAccounting(accounting),
    onSuccess: (newSheet: Sheet) => {
      queryClient.setQueryData(['accountings'], (oldData: Sheet[]) => [
        ...oldData,
        newSheet,
      ])
    },
  })
}

export const useAccountings = (): UseQueryResult<Accounting[]> => {
  return useQuery({
    queryKey: ['accountings'],
    queryFn: () => API.getAccountings(),
    staleTime: Infinity,
  })
}

export const useAccounting = (): UseQueryResult<Accounting> => {
  const params = useParams()

  if (!params.accountingId) {
    throw Error('Accounting ID is missing from params')
  }

  const accountingId = parseInt(params.accountingId, 10)

  return useQuery({
    queryKey: ['accountings', accountingId],
    queryFn: async () => API.getAccounting(accountingId),
    staleTime: Infinity,
  })
}

export const useAccountingBalance = (
  accounting: Accounting,
): UseQueryResult<number> => {
  return useQuery({
    queryKey: ['accountings', accounting.id, 'balance'],
    queryFn: async () => API.getAccountingBalance(accounting.id),
    staleTime: Infinity,
  })
}

export const useAccountingBalances = (
  accounting: Accounting,
): UseQueryResult<Record<string, number>> => {
  return useQuery({
    queryKey: ['accountings', accounting.id, 'balances'],
    queryFn: async () => API.getAccountingBalances(accounting.id),
    staleTime: Infinity,
  })
}
