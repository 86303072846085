import { useField } from "formik";
import React, { FC } from "react";
import { IconContext } from "react-icons";

interface Props {
  type?: string;
  name: string;
  placeholder?: string;
  icon?: React.ReactNode;
  size?: "sm" | "lg";
  className?: string;
  disabled?: boolean;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
}
const Input: FC<Props> = ({
  name,
  placeholder = "",
  type = "text",
  icon,
  size,
  className,
  disabled = false,
  onBlur,
  autoFocus = false,
}) => {
  const [field, meta] = useField({ name, type, placeholder });

  const sizeClasses = size === "sm" ? "py-1 px-2" : "py-2 px-4";
  return (
    <div className={`flex relative ${className}`}>
      {icon && (
        <IconContext.Provider value={{ size: "1.25em" }}>
          <span className="rounded-l-md inline-flex  items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
            {icon}
          </span>
        </IconContext.Provider>
      )}
      <input
        disabled={disabled}
        type={type}
        className={`${disabled ? "opacity-50 cursor-not-allowed" : ""} flex-1 appearance-none w-full ${sizeClasses} bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-0 outline-0 rounded-md border border-gray-300`}
        {...field}
        placeholder={placeholder}
        onBlur={onBlur}
        autoFocus={autoFocus}
      />
      {meta.touched && meta.error && (
        <div className="absolute -bottom-2 right-2 bg-white px-2 text-xs text-red-500">
          {meta.error}
        </div>
      )}
    </div>
  );
};

export default Input;
