import React, { Suspense, useState } from 'react'
import Headline from '../../Elements/Headline'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import LoadingSpinner from '../../Elements/LoadingSpinner'
import CategoriesForm from './CategoriesForm'

const Categories = () => {
  const [isExpanded, toggleExpanded] = useState(false)

  return (
    <div>
      <Headline className='mb-2'>
        <div className='flex items-center cursor-pointer' onClick={() => toggleExpanded(!isExpanded)}>
          {!isExpanded && <MdArrowRight size='1.25rem' />}
          {isExpanded && <MdArrowDropDown size='1.25rem' />}
          Kategorier
        </div>
      </Headline>

      <Suspense fallback={<LoadingSpinner className='ml-4' />}>
        {isExpanded && (
          <CategoriesForm />
        )}
      </Suspense>
    </div>
  )
}

export default Categories
