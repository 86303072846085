import React from 'react'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { MdDone } from 'react-icons/md'
import Button from '../../Forms/Button'
import Input from '../../Forms/Input'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { isCurrency } from '../../../lib/Validations'

interface Props {
  amount: number
  isEditable?: boolean
  isActive: boolean
  activate: () => void
  save: (amount: number) => void
}

const SpendColumn = ({ amount, isEditable = true, isActive, activate, save }: Props) => {
  return (
    <>
      {isActive && (
        <Formik
          initialValues={{
            amount
          }}
          validationSchema={Yup.object({
            amount: Yup.string().transform((currentValue) => {
              // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
              // This way it's possible to save floats.
              return currentValue.replaceAll('.', '').replaceAll(',', '.')
            }).required('Påkrævet').test(isCurrency)
          })}
          onSubmit={({ amount }) => save(amount)}
        >
          <div className='grow'>
            <Form>
              <div className="flex">
                <Input
                  type='number'
                  name="amount"
                  placeholder='Angiv beløb'
                  size="sm"
                  className="flex-1 sm:w-20 appearance-none"
                />
                <Button
                  size="sm"
                  type="submit"
                  className='ml-1'
                >
                  <MdDone />
                </Button>
              </div>
            </Form>
          </div>
        </Formik>
      )}
      {!isActive && (
        <div
          className={`grow ${isEditable ? 'cursor-text' : 'cursor-not-allowed'}`}
          onClick={() => {
            if (isEditable) {
              activate()
            }
          }}
        >
          {formatCurrency(amount as number)}
        </div>
      )}
    </>
  )
}

export default SpendColumn
