import React, { FC, useState } from 'react'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { getItemAmountForPeriod } from '../../../lib/Calculator'
import { Spend } from '../../../lib/Types'
import Button from '../../Forms/Button'
import { MdDone } from 'react-icons/md'
import Select from '../../Forms/Select'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { UserType } from '../../../lib/Enums/UserType'
import { useUpdateSpend } from '../lib/Hooks/Spends'
import { useBudget } from '../lib/Hooks/Budget'

interface Props {
  spend: Spend
  isEditable?: boolean
}

enum activeUser {
  NONE,
  PRIMARY,
  SECONDARY
}

const getItemAmountForPeriodForUser = (spend: Spend, user: UserType): number => {
  const amount = getItemAmountForPeriod(spend, SpendFrequency.MONTHLY)
  const multiplier = user === UserType.PRIMARY ? spend.percentage_primary_user : 100 - spend.percentage_primary_user

  return amount / 100 * multiplier
}

const getCatoryAmountForPeriod = (item: Spend, user: UserType): number => {
  let amount = 0

  item.children.forEach((child) => {
    amount += getItemAmountForPeriodForUser(child, user)
  })

  return amount
}

const SpendUserColumns: FC<Props> = ({ spend, isEditable = true }) => {
  const { data: budget } = useBudget()
  const [isActive, setIsActive] = useState(activeUser.NONE)
  const { mutateAsync: updateSpend } = useUpdateSpend(budget!)

  const saveChange = (primaryUserPercentage: number) => {
    updateSpend({ ...spend, percentage_primary_user: primaryUserPercentage, percentage_secondary_user: 100 - primaryUserPercentage })
    setIsActive(activeUser.NONE)
  }

  const getAmount = (userType: UserType) => {
    const amount = spend.children && spend.children.length > 0
      ? getCatoryAmountForPeriod(spend, userType)
      : getItemAmountForPeriodForUser(spend, userType)

    return formatCurrency(amount)
  }

  return (
    <>
      <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
        {isActive === activeUser.PRIMARY && (
          <Formik
            initialValues={{
              primary_user_percentage: spend.percentage_primary_user
            }}
            validationSchema={Yup.object({
              primary_user_percentage: Yup.number().required('Påkrævet')
            })}
            onSubmit={(values) => saveChange(values.primary_user_percentage)}
          >
            <Form>
              <div className='flex'>
                <Select name="primary_user_percentage">
                  <option value="100">100%</option>
                  <option value="50">50%</option>
                  <option value="0">0%</option>
                </Select>
                <Button type="submit" className='ml-1'>
                  <MdDone />
                </Button>
              </div>
            </Form>
          </Formik>
        )}
        {isActive !== activeUser.PRIMARY && (
          <span onClick={() => { if (isEditable) setIsActive(activeUser.PRIMARY) }}>
            {getAmount(UserType.PRIMARY)}
          </span>
        )}
      </td>
      <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
        {isActive === activeUser.SECONDARY && (
          <Formik
            initialValues={{
              secondary_user_percentage: 100 - spend.percentage_primary_user
            }}
            validationSchema={Yup.object({
              secondary_user_percentage: Yup.number().required('Påkrævet')
            })}
            onSubmit={(values) => saveChange(100 - values.secondary_user_percentage)}
          >
            <Form>
              <div className='flex'>
                <Select name="secondary_user_percentage">
                  <option value="100">100%</option>
                  <option value="50">50%</option>
                  <option value="0">0%</option>
                </Select>
                <Button size="sm" type="submit" className='ml-1'>
                  <MdDone />
                </Button>
              </div>
            </Form>
          </Formik>
        )}
        {isActive !== activeUser.SECONDARY && (
          <span onClick={() => { if (isEditable) setIsActive(activeUser.SECONDARY) }}>
            {getAmount(UserType.SECONDARY)}
          </span>
        )}
      </td>
    </>
  )
}

export default SpendUserColumns
