import React, { FC } from 'react'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { Budget, SheetCategory, Spend, SpendCreate } from '../../../lib/Types'
import { Modal } from '../../Elements/Modal'
import Headline from '../../Elements/Headline'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import { isCurrency } from '../../../lib/Validations'
import { useCategories } from '../lib/Hooks/Categories'
import { useBudget } from '../lib/Hooks/Budget'
import Select from '../../Forms/Select'
import Input from '../../Forms/Input'
import Textarea from '../../Forms/Textarea'
import Button from '../../Forms/Button'
import { useAddSpend } from '../lib/Hooks/Spends'
import { getDateFromYearAndMonth } from '../../../lib/Dates'

interface Props {
  parent?: Spend,
  toggleHide: () => void,
  show: boolean
}

const AddSpend: FC<Props> = ({ parent, toggleHide, show }) => {
  const { data: budget } = useBudget()
  const { data: categories } = useCategories(budget!)
  const { mutate: addSpend } = useAddSpend(budget!)

  const save = async (spend: SpendCreate) => {
    // If we are adding a new spend to a parent spend, make sure to set the reference.
    if (parent) {
      spend.parent_id = parent.id
    }

    addSpend(spend)

    toggleHide()
  }

  return (
    <Modal show={show} onHide={toggleHide}>
      <Headline className='mb-4'>Tilføj udgift {parent ? `til ${parent.title}` : ''}</Headline>
      <Formik
        initialValues={{
          title: '',
          description: '',
          category: parent ? parent.category.id : categories!.at(0)?.id.toString(),
          frequency: SpendFrequency.MONTHLY,
          amount: 0,
          payment_month: '1'
        }}
        validationSchema={Yup.object({
          title: Yup.string().required('Påkrævet'),
          category: Yup.string().required('Påkrævet'),
          frequency: Yup.string().required('Påkrævet'),
          amount: Yup.string().transform((currentValue) => {
            // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
            // This way it's possible to save floats.
            return currentValue.replaceAll('.', '').replaceAll(',', '.')
          }).required('Påkrævet').test(isCurrency),
          payment_month: Yup.string().required('Påkrævet')
        })}
        onSubmit={(values) => {
          const { frequency, amount, ...rest } = values

          const newSpend = {
            ...rest,
            accounting_date: getDateFromYearAndMonth(new Date().getFullYear(), parseInt(values.payment_month, 10)),
            category: categories!.find(category => category.id === parseInt(values.category as string, 10)) as SheetCategory,
            amounts: [{
              formula: amount.toString(),
              frequency
            }]
          }
          save(newSpend)
        }}
      >
        <AddSpendForm budget={budget!} parent={parent} />
      </Formik>
    </Modal>
  )
}

interface AddSpendFormProps {
  budget: Budget
  parent?: Spend
}

const AddSpendForm: FC<AddSpendFormProps> = ({ budget, parent }) => {
  const { data: categories } = useCategories(budget)
  const { values: { frequency } } = useFormikContext<any>()

  return (
    <Form>
      <>
        <p className='mb-2'>Navn på udgift</p>
        <Input
          name="title"
          placeholder="Navnet på din udgift"
          className='mb-4'
        />
        <p className='mb-2'>Note</p>
        <Textarea
          name="description"
          placeholder="Angiv en note til din udgift"
          className='mb-4'
        />
      </>
      {!parent && (
        <>
          <p className='mb-2'>Vælg kategori</p>
          <Select
            name="category"
            className='mb-4'
          >
            {categories!.map(category => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </Select>
        </>
      )}
      <p className='mb-2'>Vælg betalingsfrekvens</p>
      <Select
        name="frequency"
        className='mb-4'
      >
        <option value={SpendFrequency.MONTHLY}>Månedlig</option>
        <option value={SpendFrequency.BIMONTHLY}>Hver 2. måned</option>
        <option value={SpendFrequency.QUARTERLY}>Kvartalvis</option>
        <option value={SpendFrequency.BIYEARLY}>Halvårlig</option>
        <option value={SpendFrequency.YEARLY}>Årlig</option>
      </Select>
      {frequency === SpendFrequency.YEARLY && (
        <>
          <p className='mb-2'>Vælg betalingsmåned</p>
          <Select
            name="payment_month"
            className='mb-4'
          >
            <option value='1'>Januar</option>
            <option value='2'>Februar</option>
            <option value='3'>Marts</option>
            <option value='4'>April</option>
            <option value='5'>Maj</option>
            <option value='6'>Juni</option>
            <option value='7'>Juli</option>
            <option value='8'>August</option>
            <option value='9'>September</option>
            <option value='10'>Oktober</option>
            <option value='11'>November</option>
            <option value='12'>December</option>
          </Select>
        </>
      )}
      {frequency === SpendFrequency.BIYEARLY && (
        <>
          <p className='mb-2'>Vælg betalingsmåneder</p>
          <Select
            name="payment_month"
            className='mb-4'
          >
            <option value='1'>Januar, Juli</option>
            <option value='2'>Februar, August</option>
            <option value='3'>Marts, September</option>
            <option value='4'>April, Oktober</option>
            <option value='5'>Maj, November</option>
            <option value='6'>Juni, December</option>
          </Select>
        </>
      )}
      {frequency === SpendFrequency.QUARTERLY && (
        <>
          <p className='mb-2'>Vælg betalingsmåneder</p>
          <Select
            name="payment_month"
            className='mb-4'
          >
            <option value='1'>Januar, April, Juli, Oktober</option>
            <option value='2'>Februar, Maj, August, November</option>
            <option value='3'>Marts, Juni, September, December</option>
          </Select>
        </>
      )}
      {frequency === SpendFrequency.BIMONTHLY && (
        <>
          <p className='mb-2'>Vælg betalingsmåneder</p>
          <Select
            name="payment_month"
            className='mb-4'
          >
            <option value='1'>Januar, Marts, Maj, Juli, September, November</option>
            <option value='2'>Februar, April, Juni, August, Oktober, December</option>
          </Select>
        </>
      )}
      <p className='mb-2'>Indtast udgift</p>
      <Input
        type="number"
        name="amount"
        placeholder="Angiv beløb"
        className='mb-4'
      />
      <Button type="submit">Tilføj</Button>
    </Form>
  )
}

export default AddSpend
