import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import './index.css'
import reportWebVitals from './reportWebVitals'
import ErrorPage from './Pages/ErrorPage'
import BasePage from './Pages/BasePage'
import LoginPage, { login as loginPageAction } from './Pages/LoginPage'
import AuthenticatePage, { loader as authenticatePageLoader } from './Pages/AuthenticatePage'
import BudgetPage from './Pages/BudgetPage'
import AcceptInvitePage, { loader as acceptInviteLoader } from './Pages/AcceptInvitePage'
import ProtectedPage from './Pages/ProtectedPage'
import DashboardPage from './Pages/DashboardPage'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import AccountingPage from './Pages/AccountingPage'
import ProfilePage from './Pages/ProfilePage'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

Object.defineProperty(Array.prototype, 'clone', {
  value: function () {
    return JSON.parse(JSON.stringify(this))
  }
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <BasePage />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <ProtectedPage><DashboardPage /></ProtectedPage>
      },
      {
        path: 'login',
        element: <LoginPage />,
        action: loginPageAction
      },
      {
        path: 'profile',
        element: <ProtectedPage><ProfilePage /></ProtectedPage>
      },
      {
        path: 'authenticate',
        element: <AuthenticatePage />,
        loader: authenticatePageLoader
      },
      {
        path: 'budget/:budgetId',
        element: <ProtectedPage><BudgetPage /></ProtectedPage>
      },
      {
        path: 'accounting/:accountingId',
        element: <ProtectedPage><AccountingPage /></ProtectedPage>
      },
      {
        path: 'accept-invite',
        element: <AcceptInvitePage />,
        loader: acceptInviteLoader
      }
    ]
  }
])

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true
    }
  }
})

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
