import React, { useState } from 'react'
import Button from '../../Forms/Button'
import { Formik, Form } from 'formik'
import Input from '../../Forms/Input'
import * as Yup from 'yup'
import { Sheet, SheetCategory } from '../../../lib/Types'
import { MdAdd, MdDelete } from 'react-icons/md'
import { useCreateCategory } from '../lib/Hooks/Categories'

type Props = {
  sheet: Sheet
}

const CategoryAdd = ({ sheet }: Props) => {
  const [showAdd, toggleAdd] = useState(false)
  const { mutateAsync: createCategory, isPending } = useCreateCategory(sheet)

  return (
    <>
      {!showAdd && (
        <div className='ml-6 mt-2'>
          <Button onClick={() => toggleAdd(true)} className='w-full flex items-center justify-center'>
            <MdAdd />
          </Button>
        </div>
      )}
      {showAdd && (
        <Formik
          initialValues={{
            name: '',
            budget: ''
          }}
          validationSchema={Yup.object({
            name: Yup.string().required('Påkrævet')
          })}
          onSubmit={async ({ name, budget }) => {
            await createCategory({ name, budget: parseInt(budget, 10) } as SheetCategory)
            toggleAdd(false)
          }}
        >
          <Form>
            <div className='flex ml-6 mt-2'>
              <Input name='name' placeholder="Navn" className='grow mr-2' />
              <Input type='number' name='budget' placeholder="Budget" className='mr-2' />
              <Button
                type='button'
                theme='red'
                className='mr-2'
                onClick={() => toggleAdd(false)}
              >
                <MdDelete />
              </Button>
              <Button
                type='submit'
                loading={isPending}
              >
                <MdAdd />
              </Button>
            </div>
          </Form>
        </Formik>
      )}
    </>
  )
}

export default CategoryAdd
