import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { MdDone, MdModeEditOutline } from 'react-icons/md'
import * as Yup from 'yup'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import { useSaveUser } from '../../../../lib/Hooks/User'
import { User } from '../../../../lib/Types'
import Button from '../../../Forms/Button'
import Input from '../../../Forms/Input'

type Props = {
  user: User
}

const SalaryColumn: FC<Props> = ({ user }) => {
  const [active, toggleActive] = useState<boolean>(false)
  const { mutate: saveUser } = useSaveUser()

  return (
    <>
      <Formik
        initialValues={{
          salary: user.data?.salary || '',
        }}
        validationSchema={Yup.object({
          salary: Yup.number(),
        })}
        onSubmit={(values) => {
          const salary = values.salary
            ? parseInt(values.salary as string, 10)
            : null

          if (salary) {
            const newUser = {
              ...user,
              data: { ...user.data, salary },
            }
            saveUser(newUser)
          }

          toggleActive(false)
        }}
      >
        <Form>
          {active && (
            <div className='flex'>
              <Input name='salary' />
              <Button size='sm' type='submit' className='ml-1'>
                <MdDone />
              </Button>
            </div>
          )}
          {!active && (
            <span
              onClick={() => toggleActive(true)}
              className='flex cursor-pointer'
            >
              {formatCurrency(user.data.salary!)}
              <MdModeEditOutline size='1.1rem' />
            </span>
          )}
        </Form>
      </Formik>
    </>
  )
}

export default SalaryColumn
