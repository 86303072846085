import Config from '../../../../lib/Config'
import { SheetType } from '../Enums/SheetType'
import { Sheet } from '../../../../lib/Types'

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`
})

export const deleteSheet = async (sheet: Sheet): Promise<boolean> => {
  try {
    await fetch(`${Config.API}/${sheet.sheet_type}/${sheet.id}/`, {
      method: 'DELETE',
      headers: {
        ...getHeaders()
      }
    })
  } catch (error) {
    return false
  }

  return true
}

export const saveSheet = async (sheet: Sheet): Promise<boolean> => {
  await fetch(`${Config.API}/${sheet.sheet_type}/${sheet.id}/`, {
    method: 'PATCH',
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(sheet)
  })

  return true
}

export const inviteUser = async (sheet: Sheet): Promise<boolean> => {
  try {
    await fetch(`${Config.API}/${sheet.sheet_type}/${sheet.id}/invite/`, {
      method: 'POST',
      headers: {
        ...getHeaders(),
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email: sheet.invites })
    })
  } catch (error) {
    return false
  }

  return true
}

export const acceptInvite = async (sheetType: SheetType, sheetId: number, token: string): Promise<Sheet> => {
  const response = await fetch(`${Config.API}/${sheetType}/${sheetId}/accept-invite/?token=${token}`, {
    method: 'GET',
    headers: { 'Content-Type': 'application/json' }
  })

  return await response.json()
}
