import React from 'react'
import { Navigate, useRouteError } from 'react-router-dom'
import Navigation from '../Components/Navigation/Navigation'
import Section from '../Components/Elements/Section'

export default function ErrorPage () {
  const error = useRouteError()

  if (error === 401) {
    return <Navigate to='/login' replace state={{ message: 'Log ind for at få adgang' }} />
  }

  return (
    <div className='relative bg-white min-h-screen'>
      <Navigation />
      <Section background='cyan'>
        <div className="self-center text-xl font-light text-gray-600 sm:text-2xl">
          Hovsa!
        </div>
        <p>Der skete desværre en fejl: {error.statusText || error.message}</p>
      </Section>
    </div>
  )
}
