import Config from '../../../../lib/Config'
import { Sheet, Spend, SpendCreate, SpendUpdate } from '../../../../lib/Types'
import { formatDate, formatDateISO8601 } from '../../../../lib/Dates'
import { SheetType } from '../Enums/SheetType'

export interface FetchSpendsFilter {
  from?: Date
  to?: Date
  isExpense?: boolean
  nonPaidExpensesOnly?: boolean
}

export const getSpends = async (sheet: Sheet, filters: FetchSpendsFilter = {}) => {
  let url = `${Config.API}/${sheet.sheet_type}/${sheet.id}/spends/?`

  if (filters.from && filters.to) {
    url += `from_date=${formatDate(filters.from, 'yyyy-mm-dd')}&to_date=${formatDate(filters.to, 'yyyy-mm-dd')}`
  }

  if (filters.isExpense !== undefined) {
    url += `&is_expense=${filters.isExpense}`
  }

  if (filters.nonPaidExpensesOnly) {
    url += '&expense_paid=0'
  }

  const response = await fetch(url, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })

  return response.json()
}

interface SpendData extends SpendCreate {
  budget_id?: number;
  accounting_id?: number;
}

export const addItem = async (type: SheetType, typeId: number, data: SpendCreate): Promise<Spend> => {
  const spendData: SpendData = type === SheetType.BUDGET
    ? { ...data, budget_id: typeId }
    : { ...data, accounting_id: typeId }

  if (spendData.created_date) {
    spendData.created_date = formatDateISO8601(spendData.created_date as Date)
  }
  if (spendData.accounting_date) {
    spendData.accounting_date = formatDateISO8601(spendData.accounting_date as Date)
  }

  const response = await fetch(`${Config.API}/spends/${type}/${typeId}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(spendData)
  })

  return await response.json()
}

export const updateItem = async (item: SpendUpdate): Promise<Spend> => {
  const response = await fetch(
    `${Config.API}/spends/${item.id}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`
      },
      body: JSON.stringify(item)
    }
  )

  return response.json()
}

export const deleteItem = (item: Spend) => fetch(
  `${Config.API}/spends/${item.id}/`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  }
)
