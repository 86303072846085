import React, { FC } from 'react'
import { formatDate } from '../../../lib/Dates'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { Spend } from '../../../lib/Types'
import { MdMoneyOff, MdOutlineCalculate, MdOutlineReceiptLong } from 'react-icons/md'
import './Accounting.css'

type Props = {
  spends: Spend[]
  editSpend: (spend: Spend) => void
  showHeader?: boolean
  showCategory?: boolean
}

const ViewSpends: FC<Props> = ({ spends, editSpend, showHeader = true, showCategory = true }) => {
  return (
    <>
      <table className={'min-w-full table-accounting'}>
        <tbody>
          {showHeader && (
            <tr className="bg-gray-100 border-b border-x border-gray-300">
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Titel
              </th>
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Dato
              </th>
              {showCategory && (
                <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                  Kategori
                </th>
              )}
              <th scope="col" className="text-sm font-medium text-gray-900 px-6 py-4 text-left">
                Beløb
              </th>
            </tr>
          )}
          {spends.length === 0 && (
            <tr className="bg-white border-x border-b border-gray-300 cursor-pointer">
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap" colSpan={4}>
                Der er ikke oprettet nogle posteringer i regnskabet.
              </td>
            </tr>
          )}
          {spends.map((spend) => (
            <tr
              key={spend.id}
              className='bg-white border-x border-b border-gray-300 cursor-pointer'
              onClick={() => editSpend(spend)}
            >
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className='flex items-center justify-between'>
                  {spend.title}
                  {spend.is_expense && (
                    <div className='flex '>
                      <MdOutlineReceiptLong />
                      <span className={`text-xs ${spend.expense_paid ? '' : 'text-theme-red'} ml-1`}>
                        Udlæg for {spend.expense_name}
                      </span>
                    </div>
                  )}
                </div>
              </td>
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                {formatDate(new Date(spend.accounting_date))}
              </td>
              {showCategory && (
                <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                  {spend.category.name}
                </td>
              )}
              <td className="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                <div className='flex items-center'>
                  {formatCurrency(spend.amounts.at(-1)!.amount)}
                  <div className='flex mr-2'>
                    {!spend.exclude_from_summing && <MdOutlineCalculate className='ml-2' />}
                    {!!spend.is_expense && <MdMoneyOff className='ml-2' />}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

export default ViewSpends
