import Config from '../../../lib/Config'

const BASE_URL = `${Config.API}/ai`

export const EventSourceFactory = (() => {
  let instance: EventSource | null = null

  return {
    getInstance: function () {
      if (instance == null) {
        instance = new EventSource(`${BASE_URL}/stream/`)
      }
      return instance
    }
  }
})()
