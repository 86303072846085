import { useMutation } from '@tanstack/react-query'

import * as PaymentAPI from '../API/PaymentAPI'
import { Sheet } from '../Types'

export const usePaymentForSheet = () => {
  return useMutation({
    mutationFn: (sheet: Sheet) => PaymentAPI.payment(sheet.sheet_type, sheet.id)
  })
}
