import { Spend } from './Types'

/**
 * Generate a unique ID for an item.
 * This will default to the ID from the DB if set, otherwise try to generate a unique ID.
 */
export const generateIdForItem = (spend: Spend): string => {
  if (spend.id) {
    return `${spend.id}`
  }

  return `${spend.title}-${spend.amounts.at(-1)!.formula}-${spend.amounts.at(-1)!.frequency}`
}

export const genereateIdFromTitle = (title: string): string => {
  return title
}
