import React, { FC, useEffect, useState } from 'react'
import { Modal } from '../Elements/Modal'
import Headline from '../Elements/Headline'
import Text from '../Elements/Text'
import Button from '../Forms/Button'
import { SheetType } from './lib/Enums/SheetType'
import { Sheet } from '../../lib/Types'
import { usePaymentForSheet } from '../../lib/Hooks/Payment'

interface Props {
  sheet: Sheet
  title: string
  show: boolean
}

const Payment: FC<Props> = ({ sheet, title, show }) => {
  const [showModal, setShowModal] = useState(show)
  const [showPayment, togglePayment] = useState(false)
  const { mutateAsync: mutatePayment, isPending: isPaymentLoading } = usePaymentForSheet()

  useEffect(() => {
    setShowModal(show)
  }, [show])

  const sendPayment = async () => {
    await mutatePayment(sheet)
    togglePayment(true)
  }

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {!showPayment && (
          <>
            <Headline>{title}</Headline>
            {sheet.sheet_type === SheetType.BUDGET && (
              <Text className='mt-3'>
                For at få adgang til dit budget og alle funktioner, skal du betale 49,- kr årligt.
              </Text>
            )}
            {sheet.sheet_type === SheetType.ACCOUNTING && (
              <Text className='mt-3'>
                For at få adgang til dit regnskab og alle funktioner, skal du betale 29,- kr årligt.
              </Text>
            )}
            <Text className='mt-3'>
              Tryk på knappen herunder, for at få tilsendt et betalingslink. Så snart du har gennemført betalingen, har du fuld adgang.
            </Text>
            <div className='flex mt-6'>
              <Button onClick={sendPayment} loading={isPaymentLoading}>Betal {sheet.sheet_type === SheetType.ACCOUNTING ? '29' : '49'},- kr nu</Button>
            </div>
          </>
        )}
        {showPayment && (
          <>
            <Headline>Tak for din støtte 🙏🏼</Headline>
            <Text className='mt-3'>
              Tak for din støtte til Privat Regnskab, det sætter vi stor pris på!
            </Text>
            <Text className='mt-3'>
              Du vil om kort tid modtage et link til betaling af {sheet.sheet_type === SheetType.ACCOUNTING ? 'regnskabet' : 'budgettet'}. Når du har gennemført betalingen, vil du have adgang til {sheet.sheet_type === SheetType.ACCOUNTING ? 'regnskabet' : 'budgettet'} i et år.
            </Text>
            <Text className='mt-3'>
              Hvis du har spørgsmål, er du altid velkommen til at kontakte os på kontakt@privat-regnskab.dk.
            </Text>
            <Button className='mt-6' onClick={() => setShowModal(false)}>Luk vindue</Button>
          </>
        )}
      </Modal>
    </>
  )
}

export default Payment
