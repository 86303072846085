import React, { FC } from 'react'
import { useField } from 'formik'

interface Props {
  children: React.ReactNode
  name: string
  className?: string
}

const Checkbox: FC<Props> = ({ children, name, className }) => {
  const [field, meta] = useField({ name })

  return (
    <div className="mb-3 flex">
      <div className="relative w-10 mr-2 align-middle select-none">
        <input {...field} checked={field.value} type="checkbox" className={`checked:bg-blue-500 outline-none focus:outline-none right-4 checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-lg bg-white border-4 appearance-none cursor-pointer ${className}`}/>
        <label htmlFor={field.name} className="block h-6 overflow-hidden bg-gray-300 rounded-md cursor-pointer">
        </label>
      </div>
      <span className='flex items-center'>
        {children}
      </span>
      {meta.touched && meta.error
        ? (
          <div className="error">{meta.error}</div>
        )
        : null}
    </div>
  )
}

export default Checkbox
