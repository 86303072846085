import Config from '../../../../lib/Config'
import { SheetType } from '../Enums/SheetType'
import { Budget, BudgetAmount } from '../../../../lib/Types'

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`
})

export const downloadBudget = async (budget: Budget): Promise<void> => {
  fetch(`${Config.API}/budgets/${budget.id}/download/`, {
    headers: getHeaders()
  })
    .then(response => response.blob())
    .then(blob => {
    // Create a new object URL for the blob
      const url = window.URL.createObjectURL(blob)

      // Create a link and click it
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'budget.csv')
      document.body.appendChild(link)
      link.click()

      // Remove the link after triggering the download
      document.body.removeChild(link)
    })
}

export const getBudgets = async (): Promise<Budget[]> => {
  const response = await fetch(`${Config.API}/budgets/`, {
    headers: getHeaders()
  })

  if (response.status !== 200) {
    throw response.status
  }

  const budgets = await response.json()

  return budgets.map((budget: Budget) => {
    return {
      ...budget,
      sheet_type: SheetType.BUDGET
    }
  })
}

export const getBudget = async (id: number): Promise<Budget> => {
  const response = await fetch(`${Config.API}/budgets/${id}/`, {
    headers: getHeaders()
  })
  const budget = await response.json()

  return {
    ...budget,
    sheet_type: SheetType.BUDGET
  }
}

export const getBudgetAmounts = async (budget: Budget): Promise<BudgetAmount[]> => {
  const response = await fetch(`${Config.API}/budgets/${budget.id}/amounts/`, {
    headers: getHeaders()
  })

  return await response.json()
}

export const createBudget = async (): Promise<Budget> => {
  const response = await fetch(`${Config.API}/budgets/`, {
    method: 'POST',
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/json'
    }
  })

  const budget = await response.json()

  return {
    ...budget,
    sheet_type: SheetType.BUDGET
  }
}
