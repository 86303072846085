import { ReactNode, Suspense, useState } from 'react'
import { Outlet } from 'react-router-dom'
import LoadingSpinner from '../Components/Elements/LoadingSpinner'
import Navigation from '../Components/Navigation/Navigation'
import { SidebarContext } from '../lib/Context'

function BasePage() {
  const [sidebarContent, setSidebarContent] = useState<ReactNode | null>()

  return (
    <div className='relative bg-white min-h-screen'>
      <SidebarContext.Provider
        value={{
          showSidebar: (content: ReactNode) => {
            setSidebarContent(content)
          },
          isSidebarOpen: !!sidebarContent,
        }}
      >
        <Navigation />
        <div className='py-4 px-8 mx-auto max-w-7xl'>
          <Suspense fallback={<LoadingSpinner />}>
            <Outlet />
            <div
              style={{ backgroundColor: '#fbfbfb' }}
              className={`fixed transition-transform top-0 z-20 w-1/2 right-0 overflow-x-hidden overflow-y-scroll ${sidebarContent ? 'translate-x-0' : 'translate-x-full'}`}
            >
              <div className='absolute right-4 top-4'>
                <button
                  className='bg-transparent border border-transparent'
                  onClick={() => setSidebarContent(false)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16'
                    height='16'
                    fill='currentColor'
                    className='w-6 h-6 text-gray-700'
                    viewBox='0 0 1792 1792'
                  >
                    <path d='M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z'></path>
                  </svg>
                </button>
              </div>
              <div className='flex flex-col sm:flex-row sm:justify-around'>
                <div className='h-screen w-full'>
                  <div className='pb-10 mt-4 px-6 '>
                    <Suspense fallback={<LoadingSpinner />}>
                      {sidebarContent}
                    </Suspense>
                  </div>
                </div>
              </div>
            </div>
          </Suspense>
        </div>
      </SidebarContext.Provider>
    </div>
  )
}

export default BasePage
