import { useEffect, useState } from 'react'
import { IoSparkles } from 'react-icons/io5'
import Chat from './Chat'

const Toggle = () => {
  const [showChat, setShowChat] = useState(false)

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'i') {
        setShowChat(!showChat)
        event.preventDefault()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [showChat])

  return (
    <>
      <div
        onClick={() => setShowChat(!showChat)}
        className='inline-block align-bottom mr-2 cursor-pointer'
        title='Tryk på CTRL + E eller CMD + E for at åbne assistenten.'
      >
        <IoSparkles size='1.35rem' color='#FFC300' />
      </div>
      {showChat && <Chat close={() => setShowChat(false)} />}
    </>
  )
}

export default Toggle
