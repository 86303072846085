import React, { FC } from 'react'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(...registerables)

type Props = {
  heading?: string
  data: { [key: string]: string | number },
  budget?: number,
  tooltipCallback?: (dataIndex: number) => string | string[]
}

const LineChart: FC<Props> = ({ heading, data, budget, tooltipCallback }) => {
  const chartData = structuredClone(data)
  const secondChartData = {} as { [key: string]: number }

  // If only one item in the data object exists duplicate this one item
  // to make the line chart visible.
  if (Object.keys(data).length === 1) {
    chartData.nu = data[Object.keys(data)[0]]
  }

  if (budget) {
    Object.keys(chartData).forEach((key) => {
      secondChartData[key] = budget
    })
  }

  return (
    <Line
      data={{
        labels: Object.keys(chartData),
        datasets: [{
          label: heading,
          data: Object.values(chartData),
          fill: false,
          borderColor: 'rgb(69, 123, 157)',
          tension: 0.1
        }, {
          label: 'Second Line',
          data: Object.values(secondChartData),
          fill: false,
          borderColor: 'rgb(220, 20, 60)',
          tension: 0.1,
          pointRadius: 0,
          borderWidth: 1
        }]
      }}
      options={{
        plugins: {
          legend: {
            display: !!heading
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: function (context) {
                return context[0].label
              },
              label: function (context) {
                return tooltipCallback ? tooltipCallback(context.dataIndex) : `${context.parsed.y}`
              }
            }
          }
        }
      }}
    />
  )
}

export default LineChart
