import { FC, useEffect, useState } from 'react'
import {
  MdArrowDropDown,
  MdArrowRight,
  MdMoneyOff,
  MdOutlineCalculate,
  MdPostAdd,
} from 'react-icons/md'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { formatDate } from '../../../lib/Dates'
import { Spend } from '../../../lib/Types'
import Button from '../../Forms/Button'
import { useAccounting } from '../lib/Hooks/Accounting'
import { useCategories } from '../lib/Hooks/Categories'
import './Accounting.css'

type Props = {
  editSpend: (spend: Spend) => void
  addSpend: (category: number) => void
  spends: Spend[]
}

type ViewCategory = {
  id: number
  name: string
  sum: number
  budget?: number
  spends: Spend[]
}

const ViewCategories: FC<Props> = ({ editSpend, addSpend, spends }) => {
  const { data: accounting } = useAccounting()
  const { data: categories } = useCategories(accounting!)

  const [viewCategories, setViewCategories] = useState<
    Map<number, ViewCategory>
  >(new Map())
  const [expandedCategories, setExpandedCategories] = useState<number[]>([])

  useEffect(() => {
    const newViewCategories = new Map()

    categories!.forEach((category) => {
      newViewCategories.set(category.id, {
        id: category.id,
        name: category.name,
        budget: category.budget,
        spends: [],
        sum: 0,
      })
    })

    spends!.forEach((spend) => {
      newViewCategories.get(spend.category.id)!.spends.push(spend)
    })

    newViewCategories.forEach((category) => {
      category.sum = sumSpends(category.spends)
    })

    const filteredViewCategories = new Map([...newViewCategories.entries()])

    setViewCategories(filteredViewCategories)
  }, [spends, categories])

  const sumSpends = (spends: Spend[]) => {
    return spends.reduce((sum, spend) => sum + spend.amounts.at(-1)!.amount, 0)
  }

  const getBalanceColor = (budget: number | undefined, sum: number): string => {
    if (budget) {
      const percentage = (sum / budget) * 100

      if (percentage >= 100) {
        return 'bg-red-300'
      } else if (percentage > 90) {
        return 'bg-yellow-200'
      } else {
        return 'bg-emerald-500'
      }
    }
    return ''
  }

  return (
    <>
      {viewCategories.size === 0 && (
        <table className='min-w-full table-accounting-3'>
          <tbody>
            <tr className='bg-gray-100 border-b border-x border-gray-300'>
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                Der er ikke oprettet nogle posteringer i regnskabet.
              </td>
            </tr>
          </tbody>
        </table>
      )}

      <table className='min-w-full table-accounting'>
        <tbody>
          <tr className='bg-gray-200 border-b border-x border-gray-300'>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              Kategori
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              Budget
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              Balance
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
              Forbrug
            </td>
          </tr>
        </tbody>
      </table>

      {[...viewCategories.values()].map((category) => (
        <table key={category.id} className='min-w-full table-accounting'>
          <tbody>
            <tr className='bg-gray-100 border-b border-x border-gray-300'>
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                <div className='flex items-center cursor-pointer'>
                  <div
                    className='flex grow'
                    onClick={() => {
                      // Check if the key is in the expanded groups. If it is, remove it, if not, add it.
                      const expandedCategoryIndex = expandedCategories.indexOf(
                        category.id,
                      )
                      const newExpandedCategories = [...expandedCategories]

                      if (expandedCategoryIndex !== -1) {
                        newExpandedCategories.splice(expandedCategoryIndex, 1)
                      } else {
                        newExpandedCategories.push(category.id)
                      }

                      setExpandedCategories(newExpandedCategories)
                    }}
                  >
                    <div>
                      {expandedCategories.indexOf(category.id) !== -1 && (
                        <MdArrowDropDown size='1.25rem' />
                      )}
                      {expandedCategories.indexOf(category.id) === -1 && (
                        <MdArrowRight size='1.25rem' />
                      )}
                    </div>
                    {category.name}
                  </div>
                  <Button theme='gray' onClick={() => addSpend(category.id)}>
                    <div className='flex items-center text-sm'>
                      <MdPostAdd size='1.1rem' className='mr-2' />
                      Tilføj postering
                    </div>
                  </Button>
                </div>
              </td>
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                {category.budget && formatCurrency(category.budget, false)}
              </td>
              <td
                className={`text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap ${getBalanceColor(category?.budget, category.sum)}`}
              >
                {category.budget &&
                  formatCurrency(category.budget - category.sum)}
              </td>
              <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                <div className='flex flex-col '>
                  {formatCurrency(category.sum)}
                </div>
              </td>
            </tr>
            {expandedCategories.indexOf(category.id) !== -1 && (
              <>
                {category.spends.length === 0 && (
                  <tr className='bg-white border-x border-b border-gray-300'>
                    <td
                      className='text-sm text-gray-900 font-light pl-11 pr-6 py-4 whitespace-nowrap'
                      colSpan={3}
                    >
                      Der er ikke oprettet nogle posteringer i denne kategori.
                    </td>
                  </tr>
                )}
                {category.spends.map((spend) => (
                  <tr
                    key={spend.id}
                    className='bg-white border-x border-b border-gray-300 cursor-pointer'
                    onClick={() => editSpend(spend)}
                  >
                    <td className='text-sm text-gray-900 font-light pl-11 pr-6 py-4 whitespace-nowrap'>
                      {spend.title}
                      {spend.is_expense && (
                        <span className='text-xs text-theme-red ml-2'>
                          Udlæg for {spend.expense_name}
                        </span>
                      )}
                    </td>
                    <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                      {formatDate(new Date(spend.accounting_date))}
                    </td>
                    <td className='text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap'>
                      <div className='flex items-center'>
                        {formatCurrency(spend.amounts.at(-1)!.amount)}
                        <div className='flex mr-2'>
                          {!spend.exclude_from_summing && (
                            <MdOutlineCalculate className='ml-2' />
                          )}
                          {!!spend.is_expense && (
                            <MdMoneyOff className='ml-2' />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      ))}
    </>
  )
}

export default ViewCategories
