import React, { useContext } from 'react'
import { MdDeleteOutline, MdPostAdd, MdAutoGraph, MdSettings } from 'react-icons/md'
import { IconContext } from 'react-icons'
import { Sheet, Spend } from '../../../lib/Types'
import { SidebarContext } from '../../../lib/Context'
import SpendConfiguration from './SpendConfiguration'
import { useDeleteSpend, useUpdateSpend } from '../lib/Hooks/Spends'
import SpendDevelopment from './SpendDevelopment'
import { useBudget } from '../lib/Hooks/Budget'

interface Props {
  spend: Spend
  isChild?: boolean
  isCategory?: boolean
  toggleAddSpend: () => void
  toggleChildren?: () => void
}

const TitleColumn = ({ spend, isChild, isCategory, toggleAddSpend, toggleChildren }: Props) => {
  const { data: budget } = useBudget()
  const { showSidebar, isSidebarOpen } = useContext(SidebarContext)
  const { mutate: updateSpend } = useUpdateSpend(budget!)
  const { mutate: deleteSpend } = useDeleteSpend(budget!)

  return (
    <div className='flex items-center'>
      <div
        className={`flex flex-col grow ${isCategory ? 'cursor-pointer' : ''}`}
        onClick={toggleChildren}
      >
        <p className={`${isChild ? 'ml-5' : ''}`}>{spend.title}</p>
        {spend.description && (<p className={`italic ${isChild ? 'ml-5' : ''}`}>{spend.description}</p>)}
      </div>

      {!isCategory && (
        <MdAutoGraph
          size='1.15rem'
          className="mr-2 cursor-pointer"
          onClick={() => {
            isSidebarOpen
              ? showSidebar(null)
              : showSidebar(
                <SpendDevelopment spend={spend} />
              )
          }}
        />
      )}
      {!isChild && (
        <IconContext.Provider value={{ size: '1.25em', className: 'mr-1' }}>
          <MdPostAdd
            size='1.15rem'
            onClick={toggleAddSpend}
            className="mr-2 cursor-pointer"
          />
        </IconContext.Provider>
      )}
      <MdSettings
        size='1.15rem'
        className='mr-2 cursor-pointer'
        onClick={() => {
          isSidebarOpen
            ? showSidebar(null)
            : showSidebar(
              <SpendConfiguration
                saveSpend={async (spend) => {
                  updateSpend(spend)
                  showSidebar(null)
                }}
                spend={spend}
              />
            )
        }}
      />
      <IconContext.Provider value={{ size: '1.25em' }}>
        <MdDeleteOutline
          size='1.15rem'
          onClick={async () => {
            if (window.confirm('Dette vil slette udgiften. Er du sikker?')) {
              deleteSpend(spend)
            }
          }}
          className="cursor-pointer"
        />
      </IconContext.Provider>
    </div>
  )
}

export default TitleColumn
