export const isCurrency = {
  name: 'is-currency',
  skipAbsent: true,
  test (value: string | undefined, ctx: any) {
    const regex = /^[-+]?(\d+(\.\d{1,2})?)([-+]\d+(\.\d{1,2})?)*$/
    if (value && !regex.test(value)) {
      return ctx.createError({ message: 'Beløbet skal være et tal eller en formel' })
    }
    return true
  }
}

