import React, { FC } from 'react'
import { Chart as ChartJS, registerables } from 'chart.js'
import { Bar } from 'react-chartjs-2'

ChartJS.register(...registerables)

type BarData = {
  name: string
  value: string | number
  color: string
}

type Props = {
  heading?: string
  data: BarData[]
  tooltipCallback?: (dataIndex: number) => string | string[]
}

const BarChart: FC<Props> = ({ data, tooltipCallback }) => {
  return (
    <Bar
      className='w-full'
      data={{
        labels: data.map((item: BarData) => item.name),
        datasets: [{
          label: '',
          data: data.map((item: BarData) => item.value),
          backgroundColor: data.map((item: BarData) => item.color)
        }]
      }}
      options={{
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            displayColors: false,
            callbacks: {
              title: function (context) {
                return context[0].label
              },
              label: function (context) {
                return tooltipCallback ? tooltipCallback(context.dataIndex) : `${context.parsed.y}`
              }
            }
          }
        },
        scales: {
          x: {
            ticks: {
              autoSkip: false,
              maxRotation: 90,
              minRotation: 90
            }
          }
        }
      }}
    />
  )
}

export default BarChart
