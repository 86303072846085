import { FC } from 'react'
import { Link } from 'react-router-dom'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { Accounting } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import { AccountingType } from '../lib/Enums/AccountingType'
import { useAccountingBalance } from '../lib/Hooks/Accounting'
import { useSpends } from '../lib/Hooks/Spends'
import { summarizeItems } from './Accounting'
import AccountingDevelopmentChart from './AccountingDevelopmentChart'

interface Props {
  accounting: Accounting
}

const getFromDate = (accounting: Accounting) => {
  const date = new Date()
  switch (accounting.type) {
    case AccountingType.WEEK: {
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1)
      return new Date(date.getFullYear(), date.getMonth(), diff)
    }
    case AccountingType.MONTH:
      return new Date(date.getFullYear(), date.getMonth(), 1)
    case AccountingType.YEAR:
      return new Date(date.getFullYear(), 0, 1)
    default:
      return new Date(accounting.created_date)
  }
}

const getToDate = (accounting: Accounting) => {
  const date = new Date()
  switch (accounting.type) {
    case AccountingType.WEEK: {
      const dayOfWeek = date.getDay()
      return dayOfWeek === 0
        ? date
        : new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate() - dayOfWeek + 7,
          )
    }
    case AccountingType.MONTH:
      return new Date(date.getFullYear(), date.getMonth() + 1, 0)
    case AccountingType.YEAR:
      return new Date(date.getFullYear(), 11, 31)
    default:
      return date
  }
}

const Card: FC<Props> = ({ accounting }) => {
  const { data: spends } = useSpends(accounting, {
    from: getFromDate(accounting),
    to: getToDate(accounting),
  })
  const { data: balance } = useAccountingBalance(accounting!)

  const getAccountingName = () => {
    switch (accounting.type) {
      case AccountingType.MONTH:
        return `Månedsregnskab ${accounting.id}`
      case AccountingType.WEEK:
        return `Ugeregnskab ${accounting.id}`
      case AccountingType.YEAR:
        return `Årsregnskab ${accounting.id}`
      default:
        return `Regnskab ${accounting.id}`
    }
  }

  const getAccountingSpendTitle = () => {
    switch (accounting.type) {
      case AccountingType.REGULAR:
        return 'Forbrug'
      default:
        return 'Forbrug for periode'
    }
  }

  return (
    <div className='bg-slate-200 p-4 mt-4'>
      <Link to={`/accounting/${accounting.id}`}>
        <Headline size='base' className='mb-4'>
          <strong>{accounting.name || getAccountingName()}</strong>
        </Headline>
        <Text size='base'>{getAccountingSpendTitle()}</Text>
        <Headline size='4xl' className='mb-4'>
          {formatCurrency(summarizeItems(spends!), false)}
        </Headline>
        {accounting.dedicated_account && (
          <>
            <Text size='base'>Nuværende saldo</Text>
            <Headline size='3xl' className='mb-4'>
              kr. {balance !== null ? formatCurrency(balance!, false) : 0}
            </Headline>
          </>
        )}
        {!!accounting.balance && !!accounting.budget && (
          <div className='mt-6'>
            <Text size='base'>Tilbage for periode</Text>
            <Headline size='3xl'>
              kr.{' '}
              {formatCurrency(
                accounting.budget - summarizeItems(spends!),
                false,
              )}
            </Headline>
          </div>
        )}

        {accounting.type === AccountingType.REGULAR && !!accounting.budget && (
          <div className='mt-6'>
            <Text size='base'>Budget</Text>
            <Headline size='3xl'>
              kr. {formatCurrency(accounting.budget, false)}
            </Headline>
          </div>
        )}

        {accounting.type !== AccountingType.REGULAR && (
          <div className='mt-6'>
            <AccountingDevelopmentChart
              accounting={accounting}
              showEmptyWarning={false}
            />
          </div>
        )}
      </Link>
    </div>
  )
}

export default Card
