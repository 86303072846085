import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CreateAccounting from '../Components/Sheets/Accounting/CreateAccounting'
import Headline from '../Components/Elements/Headline'
import Button from '../Components/Forms/Button'
import CardBudget from '../Components/Sheets/Budget/Card'
import CardAccounting from '../Components/Sheets/Accounting/Card'
import { Accounting, Budget } from '../lib/Types'
import { SheetType } from '../Components/Sheets/lib/Enums/SheetType'
import { useAccountings } from '../Components/Sheets/lib/Hooks/Accounting'
import useBudgets, { useCreateBudget } from '../Components/Sheets/lib/Hooks/Budget'

export default function DashboardPage () {
  const navigate = useNavigate()
  const { data: budgets } = useBudgets()
  const { data: accountings } = useAccountings()
  const { mutateAsync: createBudget } = useCreateBudget()
  const [showCreateAccounting, toggleShowCreateAccounting] = useState(false)

  const itemsToRender: (Budget | Accounting)[] = ([] as (Budget | Accounting)[]).concat(budgets || [], accountings || [])
  const column1 = itemsToRender.filter((_, index) => index % 3 === 0)
  const column2 = itemsToRender.filter((_, index) => index % 3 === 1)
  const column3 = itemsToRender.filter((_, index) => index % 3 === 2)

  const addNewBudget = async () => {
    if (budgets!.length > 0 && !confirm('Du har allerede et eksisterende budget. Vil du oprette et nyt?')) {
      return
    }

    const budget = await createBudget()

    return navigate(`/budget/${budget.id}/`)
  }

  return (
    <>
      <Headline>
        <div className='flex flex-col md:flex-row justify-between  mb-2'>
          <Headline className='mb-4 md:ml-0'>Overblik</Headline>
          <div className='flex flex-col md:flex-row justify-between'>
            <Button
              onClick={() => addNewBudget()}
              className="ml-0 md:ml-2 mb-4 md:mb-0"
            >
              Opret Budget
            </Button>
            <Button
              onClick={() => toggleShowCreateAccounting(!showCreateAccounting)}
              className="ml-0 md:ml-2"
            >
              Opret Regnskab
            </Button>
          </div>
        </div>
      </Headline>
      {itemsToRender.length === 0 && (
        <>
          <p>Du har ikke oprettet hverken et budget eller et regnskab.</p>
          <p>Opret et budget eller et regnskab ved at bruge knapperne øverst til højre.</p>
        </>
      )}
      <div className='flex flex-wrap md:flex-nowrap justify-between'>
        <div className='w-full md:w-1/3 mr-0 md:mr-2'>
          {column1.map((item, index) => (
            <div key={index}>
              {item.sheet_type === SheetType.BUDGET
                ? <CardBudget budget={item as Budget} />
                : <CardAccounting accounting={item as Accounting} />
              }
            </div>
          ))}
        </div>
        <div className='w-full md:w-1/3 mx-0 md:mx-2'>
          {column2.map((item, index) => (
            <div key={index}>
              {item.sheet_type === SheetType.BUDGET
                ? <CardBudget budget={item as Budget} />
                : <CardAccounting accounting={item as Accounting} />
              }
            </div>
          ))}
        </div>
        <div className='w-full md:w-1/3 ml-0 md:ml-2'>
          {column3.map((item, index) => (
            <div key={index}>
              {item.sheet_type === SheetType.BUDGET
                ? <CardBudget budget={item as Budget} />
                : <CardAccounting accounting={item as Accounting} />
              }
            </div>
          ))}
        </div>
      </div>

      <CreateAccounting showModal={showCreateAccounting} toggleModal={toggleShowCreateAccounting} />
    </>
  )
}
