import { SheetType } from '../../lib/Enums/SheetType'

export const getSingularName = (sheetType: SheetType) => {
  switch (sheetType) {
  case SheetType.BUDGET:
    return 'budget'
  case SheetType.ACCOUNTING:
    return 'regnskab'
  }
}

export const getImperativName = (sheetType: SheetType) => {
  switch (sheetType) {
  case SheetType.BUDGET:
    return 'budgettet'
  case SheetType.ACCOUNTING:
    return 'regnskabet'
  }
}
