import { FC, useState } from 'react'
import { sumForUser } from '../../../../lib/Calculator'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import { UserType } from '../../../../lib/Enums/UserType'
import { Spend } from '../../../../lib/Types'
import Headline from '../../../Elements/Headline'
import Text from '../../../Elements/Text'
import { useBudget } from '../../lib/Hooks/Budget'

import { Modal } from '../.././../Elements/Modal'
import AllowanceColumn from './AllowanceColumn'
import BalancedAllowance from './BalancedAllowance'
import BalancedAllowanceHelpText from './BalancedAllowanceHelpText'
import SalaryColumn from './SalaryColumn'
type Props = {
  items: Spend[]
}

const Allowance: FC<Props> = ({ items }) => {
  const { data: budget } = useBudget()
  const [info, setInfo] = useState<string | null>(null)

  const isSalaryInputted = () => {
    return budget!.user
      ? budget!.author.data.salary && budget!.user?.data.salary
      : budget!.author.data.salary
  }

  const getUserName = (user: UserType) => {
    if (user === UserType.PRIMARY) {
      return budget!.author.name || budget!.author.email.split('@')[0]
    }
    return budget!.user!.name || budget!.user!.email.split('@')[0]
  }

  const budgetForAuthor = sumForUser(items, UserType.PRIMARY)
  const budgetForUser = sumForUser(items, UserType.SECONDARY)

  return (
    <>
      <Headline className='mb-2 mt-5'>Rådighedsbeløb</Headline>
      <Text className='mb-5'>
        Herunder kan se beregningen af dit rådighedsbeløb. Rådighedsbeløbet er
        de penge du har tilbage, når alle dine faste udgifter er betalt.
      </Text>
      <table className='min-w-full mb-5'>
        <tbody>
          <tr className='bg-table-sub-header border-y border-x border-gray-300'>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              Type
            </th>
            <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
              {getUserName(UserType.PRIMARY)}
            </th>
            {budget!.user && (
              <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                {getUserName(UserType.SECONDARY)}
              </th>
            )}
          </tr>
          <tr className='bg-white border-x border-b border-gray-300'>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              Indtægter
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              <SalaryColumn user={budget!.author} />
            </td>
            {budget!.user && (
              <td className='text-sm text-gray-900 font-light px-6 py-4'>
                <SalaryColumn user={budget!.user} />
              </td>
            )}
          </tr>
          <tr className='bg-white border-x border-b border-gray-300'>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              Udgifter
              <p className='italic'>
                Beløbet I hver skal overføre til den fælles budgetkonto.
              </p>
            </td>
            <td className='text-sm text-gray-900 font-light px-6 py-4'>
              {formatCurrency(budgetForAuthor)}
            </td>
            {budget!.user && (
              <td className='text-sm text-gray-900 font-light px-6 py-4'>
                {formatCurrency(budgetForUser)}
              </td>
            )}
          </tr>
          {isSalaryInputted() && (
            <tr className='bg-white border-x border-b border-gray-300'>
              <td className='text-sm text-gray-900 font-bold px-6 py-4'>
                Rådighedsbeløb
              </td>
              <td className='text-sm text-gray-900 font-bold px-6 py-4'>
                <AllowanceColumn userType={UserType.PRIMARY} />
              </td>
              {budget!.user && (
                <td className='text-sm text-gray-900 font-bold px-6 py-4'>
                  <AllowanceColumn userType={UserType.SECONDARY} />
                </td>
              )}
            </tr>
          )}
          {budget!.user && <BalancedAllowance />}
        </tbody>
      </table>
      {budget!.user && <BalancedAllowanceHelpText />}
      <Modal onHide={() => setInfo(null)} show={info !== null}>
        {info}
      </Modal>
    </>
  )
}

export default Allowance
