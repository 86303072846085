import Config from '../Config'
import { User } from '../Types'

const BASE_URL = `${Config.API}/user`

/**
 * Send a magic link for authentication to the user's email address
 */
export const sendMagicLink = async (email: string): Promise<User> => {
  const response = await fetch(`${BASE_URL}/magic-link/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ user: { email } }),
  })
  return await response.json()
}

/**
 * Log out a user by invalidating the token
 */
export const logout = async () => {
  await fetch(`${BASE_URL}/logout/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })
}

/**
 * Get the authenticated user
 */
export const getAuthenticatedUser = async (): Promise<User | null> => {
  if (localStorage.getItem('token') === null) {
    return null
  }

  const response = await fetch(`${BASE_URL}/`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  })

  return await response.json()
}

/**
 * Update the user and all of its properties
 */
export const saveUser = async (user: User): Promise<boolean> => {
  await fetch(`${BASE_URL}/`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(user),
  })
  return true
}
