import { UseQueryResult, useQueryClient, useMutation, useQuery } from '@tanstack/react-query'
import * as API from '../API/SpendAPI'
import { Sheet, Spend, SpendCreate, SpendUpdate } from '../../../../lib/Types'
import { SheetType } from '../Enums/SheetType'
import { formatDate } from '../../../../lib/Dates'

const getQueryKey = (sheet: Sheet, filters?: API.FetchSpendsFilter) => {
  const queryKey = [sheet.sheet_type, sheet.id, 'spends']

  if (filters) {
    if (typeof filters.isExpense !== 'undefined') {
      queryKey.push(filters.isExpense ? 'expenses' : '')
    }

    if (filters.nonPaidExpensesOnly) {
      queryKey.push('non-paid')
    }

    if (filters.from && filters.to) {
      queryKey.push(`from:${formatDate(filters.from)}`)
      queryKey.push(`to:${formatDate(filters.to)}`)
    }
  }

  return queryKey
}

export const useSpends = (sheet: Sheet, filters: API.FetchSpendsFilter = {}): UseQueryResult<Spend[]> => {
  return useQuery({
    queryKey: getQueryKey(sheet, filters),
    queryFn: () => API.getSpends(sheet, filters),
    staleTime: Infinity
  })
}

export const useAddSpend = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (spendToCreate: SpendCreate) => API.addItem(sheet.sheet_type, sheet.id, spendToCreate),
    onSuccess: (spendCreated: Spend) => {
      const queryKey = getQueryKey(sheet)

      queryClient.cancelQueries({ queryKey })

      queryClient.setQueryData(
        queryKey,
        (current: Spend[]) => [spendCreated, ...current]
      )

      // When a spend is added, the amounts for the sheet need to be updated.
      // This is calculated on the BE so invalidate the cache to fetch the updated.
      const amountsQueryKey = sheet.sheet_type === SheetType.BUDGET
        ? ['budgets', sheet.id, 'amounts']
        : ['accountings', sheet.id, 'amounts']
      queryClient.invalidateQueries({ queryKey: amountsQueryKey })

      // When a spend is added, the balances for the accounting need to be updated.
      if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({ queryKey: ['accountings', sheet.id, 'balance'] })
        queryClient.invalidateQueries({ queryKey: ['accountings', sheet.id, 'balances'] })
      }
    }
  })
}

export const useUpdateSpend = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (spendToUpdate: SpendUpdate) => API.updateItem(spendToUpdate),
    // It's not possible to use an optimistic update because the amount for the
    // spend is added to the array in the BE.
    onSuccess: (spendUpdated: SpendUpdate) => {
      const queryKey = getQueryKey(sheet)

      queryClient.cancelQueries({ queryKey })

      queryClient.setQueryData(
        getQueryKey(sheet),
        (current: Spend[]) => current.map((spend) => spend.id === spendUpdated.id ? spendUpdated : spend)
      )

      // When a spend is updated, the amounts for the sheet needs to be updated.
      // This is calculated on the BE so invalidate the cache to fetch the updated.
      const amountsQueryKey = spendUpdated.budget_id
        ? ['budgets', spendUpdated.budget_id, 'amounts']
        : ['accountings', spendUpdated.accounting_id, 'amounts']

      queryClient.invalidateQueries({ queryKey: amountsQueryKey })

      // When a spend is added, the balances for the accounting need to be updated.
      if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({ queryKey: ['accountings', sheet.id, 'balance'] })
        queryClient.invalidateQueries({ queryKey: ['accountings', sheet.id, 'balances'] })
      }
    }
  })
}

export const useDeleteSpend = (sheet: Sheet) => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (spend: Spend) => API.deleteItem(spend),
    onMutate: (spendToDelete: Spend): Spend[] => {
      const queryKey = getQueryKey(sheet)

      queryClient.cancelQueries({ queryKey })

      const previousState = queryClient.getQueryData(queryKey) as Spend[]

      const newSpends = previousState.filter((spend) => spend.id !== spendToDelete.id)

      queryClient.setQueryData(queryKey, newSpends)

      return previousState
    },
    onError: (err, spendToDelete: Spend, previousState: Spend[] | undefined) => {
      if (previousState) {
        const queryKey = getQueryKey(sheet)

        queryClient.setQueryData(queryKey, previousState)
      }

      console.error(err)
    },
    onSuccess: (data, spend: Spend) => {
      // When a spend is deleted, the amounts for the sheet need to be updated.
      // This is calculated on the BE so invalidate the cache to fetch the updated.
      const queryKey = spend.budget_id
        ? ['budgets', spend.budget_id, 'amounts']
        : ['accountings', spend.accounting_id, 'amounts']

      queryClient.invalidateQueries({ queryKey })

      // When a spend is added, the balances for the accounting need to be updated.
      if (sheet.sheet_type === SheetType.ACCOUNTING) {
        queryClient.invalidateQueries({ queryKey: ['accountings', sheet.id, 'balance'] })
        queryClient.invalidateQueries({ queryKey: ['accountings', sheet.id, 'balances'] })
      }
    }
  })
}
