import React from 'react'
import * as SheetAPI from '../Components/Sheets/lib/API/SheetAPI'
import Headline from '../Components/Elements/Headline'
import { SheetType } from '../Components/Sheets/lib/Enums/SheetType'

export async function loader ({ request }: { request: any }) {
  const url = new URL(request.url)
  const token = url.searchParams.get('token')
  const sheetType = url.searchParams.get('sheet-type')
  const sheetId = url.searchParams.get('sheet-id')

  if (!token || !sheetType || !sheetId) {
    throw new Response('Not Found', { status: 404 })
  }

  const budget = await SheetAPI.acceptInvite(sheetType as SheetType, parseInt(sheetId, 10), token)

  return { budget }
}

function AcceptInvitePage () {
  return (
    <Headline>Invitation blev accepteret</Headline>
  )
}

export default AcceptInvitePage
