import React, { FC } from 'react'
import { Modal } from '../../Elements/Modal'
import Select from '../../Forms/Select'
import { Form, Formik, useFormikContext } from 'formik'
import * as Yup from 'yup'
import Input from '../../Forms/Input'
import Checkbox from '../../Forms/Checkbox'
import Button from '../../Forms/Button'
import { isCurrency } from '../../../lib/Validations'
import { AccountingType } from '../lib/Enums/AccountingType'
import { useNavigate } from 'react-router-dom'
import Headline from '../../Elements/Headline'
import { useCreateAccounting } from '../lib/Hooks/Accounting'

type Props = {
  showModal: boolean
  toggleModal: (show: boolean) => void
}

const CreateAccounting: FC<Props> = ({ showModal, toggleModal }) => {
  const { mutateAsync: createAccounting } = useCreateAccounting()
  const navigate = useNavigate()

  return (
    <>
      <Modal show={showModal} onHide={() => toggleModal(false)}>
        <Formik
          initialValues={{
            type: AccountingType.REGULAR,
            budget: '',
            dedicated_account: true,
            starting_balance: 0
          }}
          validationSchema={Yup.object({
            type: Yup.string().required('Påkrævet'),
            budget: Yup.number(),
            dedicated_account: Yup.boolean().required('Påkrævet'),
            starting_balance: Yup.string().transform((currentValue) => {
              // First remove all danish thousand seperators (.) and then replace the danish delimiter seperator (,) with the english one (.).
              // This makes it's possible to save floats.
              return currentValue.replaceAll('.', '').replaceAll(',', '.')
            }).required('Påkrævet').test(isCurrency)
          })}
          onSubmit={async (values) => {
            const accounting = await createAccounting({ ...values, budget: parseInt(values.budget, 10) })

            return navigate(`/accounting/${accounting.id}/`)
          }}
        >
          <CreateAccountingForm />
        </Formik>
      </Modal>
    </>
  )
}

const CreateAccountingForm = () => {
  const { values: { type, dedicated_account } } = useFormikContext<any>()

  return (
    <Form>
      <Headline className='mb-4'>Opret regnskab</Headline>
      <p className='mb-2 font-bold'>Vælg type</p>
      <Select name="type" className='mb-3'>
        <option value={AccountingType.REGULAR}>Regnskab</option>
        <option value={AccountingType.YEAR}>Årsregnskab</option>
        <option value={AccountingType.MONTH}>Månedsregnskab</option>
        <option value={AccountingType.WEEK}>Ugeregnskab</option>
      </Select>
      <p className='mb-5 italic'>
        {type === AccountingType.REGULAR && (<span>Opret et almindeligt fortløbende regnskab, til at holde styr på økonomi i forbindelse med f.eks. events eller projekter.</span>)}
        {type === AccountingType.MONTH && (<span>Opret et regnskab der er periodiseret i måneder. Hermed kan du holde styr på forbruget måned til måned, f.eks. for en forbrugtkonto.</span>)}
        {type === AccountingType.WEEK && (<span>Opret et regnskab der er periodiseret i uger. Hermed kan du holde styr på forbruget uge til uge, f.eks. for en madkonto.</span>)}
      </p>
      <p className='mb-2 font-bold'>Budget</p>
      <Input name="budget" placeholder="Angiv beløb" type="number" className='mb-2'></Input>
      <p className='mb-7 italic'>Angiv et beløb for perioden, hvis du vil bruge regnskabet til at hjælpe dig med at holde et budget.</p>
      <Checkbox name="dedicated_account">
        Jeg har en dedikeret konto i banken til dette regnskab
      </Checkbox>
      {dedicated_account && (
        <>
          <p className='mt-7 mb-2 font-bold'>Saldo</p>
          <Input type="number" name="starting_balance" placeholder='Angiv den nuværende saldo' />
          <p className='italic mt-2'>Hvad er den nuværende saldo på kontoen?</p>
        </>
      )}
      <Button type="submit" className='mt-5'>Opret</Button>
    </Form>
  )
}

export default CreateAccounting
