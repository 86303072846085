import { MdOutlineAutorenew } from 'react-icons/md'
import { allowanceForUser } from '../../../../lib/Calculator'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import { UserType } from '../../../../lib/Enums/UserType'
import { useBudget } from '../../lib/Hooks/Budget'
import { useUpdateSheet } from '../../lib/Hooks/Sheet'
import { useSpends } from '../../lib/Hooks/Spends'
import BalancedAllowanceColumn from './BalancedAllowanceColumn'

const BalancedAllowance = () => {
  const { data: budget } = useBudget()
  const { data: items } = useSpends(budget!)
  const { mutate: updateBudget } = useUpdateSheet()

  const allowanceForAuthor = allowanceForUser(
    items!,
    UserType.PRIMARY,
    budget!.author.data.salary!,
  )
  const allowanceForOtherUser = allowanceForUser(
    items!,
    UserType.SECONDARY,
    budget!.user!.data.salary!,
  )

  const defaultBalancedAllowance =
    (allowanceForOtherUser + allowanceForAuthor) / 2

  const balancedAllowanceForAuthor =
    budget!.data.authorBalancedAllowance || defaultBalancedAllowance
  const balancedAllowanceForUser =
    budget!.data.userBalancedAllowance || defaultBalancedAllowance

  const maximumBalancedAllowance = allowanceForAuthor + allowanceForOtherUser

  const resetBalancedAllowance = () => {
    const newBudget = structuredClone(budget)
    newBudget!.data.authorBalancedAllowance = defaultBalancedAllowance
    newBudget!.data.userBalancedAllowance = defaultBalancedAllowance

    updateBudget(newBudget!)
  }

  const updateBalancedAllowance = (allowance: number, isAuthor: boolean) => {
    const propertyName = isAuthor
      ? 'authorBalancedAllowance'
      : 'userBalancedAllowance'
    const otherPropertyName = isAuthor
      ? 'userBalancedAllowance'
      : 'authorBalancedAllowance'

    const newBudget = structuredClone(budget)
    newBudget!.data[propertyName] = allowance
    newBudget!.data[otherPropertyName] = maximumBalancedAllowance - allowance

    updateBudget(newBudget!)
  }

  if (allowanceForAuthor === allowanceForOtherUser) {
    return null
  }

  return (
    <>
      <tr className='bg-white border-x border-b border-gray-300'>
        <td className='text-sm text-gray-900 font-bold px-6 py-4'>
          <span className='flex'>
            Balanceret rådighedsbeløb
            <MdOutlineAutorenew
              size='1.1rem'
              className='ml-2 cursor-pointer'
              title='Klik her for at nulstille det balancerede rådighedsbeløb'
              onClick={resetBalancedAllowance}
            />
          </span>
          <p className='italic font-normal'></p>
        </td>
        <td className='text-sm text-gray-900 font-bold px-6 py-4'>
          <BalancedAllowanceColumn
            allowance={balancedAllowanceForAuthor}
            allowanceUpdated={(allowance: number) =>
              updateBalancedAllowance(allowance, true)
            }
            maximumAllowance={maximumBalancedAllowance}
          />
        </td>
        <td className='text-sm text-gray-900 font-bold px-6 py-4'>
          <BalancedAllowanceColumn
            allowance={balancedAllowanceForUser}
            allowanceUpdated={(allowance: number) =>
              updateBalancedAllowance(allowance, false)
            }
            maximumAllowance={maximumBalancedAllowance}
          />
        </td>
      </tr>
      <tr className='bg-white border-x border-b border-gray-300'>
        <td className='text-sm text-gray-900 font-light px-6 py-4'>
          Balancerede udgifter
          <p className='italic'>
            For at opnå et balanceret rådighedsbeløb, er dette beløbet I hver
            især skal overføre til den fælles budgetkonto.
          </p>
          <p className='italic'>
            Hvis beløbet er negativt, betyder det at penge skal overføres fra
            budgetkonto til lønkontoen.
          </p>
        </td>
        <td className='text-sm text-gray-900 font-light px-6 py-4'>
          {formatCurrency(
            budget!.author.data.salary! - balancedAllowanceForAuthor,
          )}
        </td>
        <td className='text-sm text-gray-900 font-light px-6 py-4'>
          {formatCurrency(
            budget!.user!.data.salary! - balancedAllowanceForUser,
          )}
        </td>
      </tr>
    </>
  )
}

export default BalancedAllowance
