import React, { FC, Suspense } from 'react'
import Headline from '../../Elements/Headline'
import LoadingSpinner from '../../Elements/LoadingSpinner'
import BudgetDevelopmentChart from './BudgetDevelopmentChart'
import { Budget } from '../../../lib/Types'

interface Props {
  budget: Budget

}

const BudgetDevelopment: FC<Props> = ({ budget }) => (
  <>
    <Headline>Udvikling</Headline>
    <p className='mt-2 mb-5'>Her kan du se udviklingen af dit budget. Det betyder at når du f.eks. tilføjer flere udgifter til budgettet, vil du se kurven gå opad. Dette giver et overblik over hvordan din økonomi udvikler sig.</p>
    <Suspense fallback={<LoadingSpinner />}>
      <BudgetDevelopmentChart budget={budget} />
    </Suspense>
  </>
)

export default BudgetDevelopment
