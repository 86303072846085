import { useQueryClient } from '@tanstack/react-query'

import debounce from 'debounce'
import { Form, Formik } from 'formik'
import { marked } from 'marked'
import { FC, useEffect, useState } from 'react'
import { IoSparkles } from 'react-icons/io5'
import { useNavigate, useParams } from 'react-router-dom'
import Headline from '../Elements/Headline'
import Button from '../Forms/Button'
import Input from '../Forms/Input'
import { chat } from './lib/AIAPI'
import { EventSourceFactory } from './lib/EventSource'

type Props = {
  close: () => void
}
let isEventListenerAdded = false

const Chat: FC<Props> = ({ close }) => {
  const navigate = useNavigate()
  const [question, setQuestion] = useState('')
  const [showError, toggleError] = useState(false)
  const [isLoading, toggleLoading] = useState(false)
  const queryClient = useQueryClient()
  const params = useParams()
  const budgetId = params.budgetId || undefined

  const eventSource = EventSourceFactory.getInstance()

  const sendMessage = async (message: string) => {
    const targetContainer = document.getElementById('answer')

    toggleError(false)
    toggleLoading(true)

    setQuestion(message)

    targetContainer!.innerHTML = ''

    try {
      const response = await chat(message, budgetId)

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
    } catch (error) {
      toggleError(true)
      toggleLoading(false)
    }
  }

  const streamFinished = debounce(async () => {
    // Convert the answer to Markdown
    const targetContainer = document.getElementById('answer')
    const html = await marked.parse(targetContainer!.innerHTML)
    targetContainer!.innerHTML = html

    // Clear the query cache for budget
    const queryKey = budgetId
      ? ['budgets', parseInt(budgetId, 10)]
      : ['budgets']
    queryClient.invalidateQueries({ queryKey })
  }, 500)

  useEffect(() => {
    const targetContainer = document.getElementById('answer')

    if (!isEventListenerAdded) {
      isEventListenerAdded = true

      eventSource.addEventListener('message', (e) => {
        toggleLoading(false)
        targetContainer!.innerHTML += e.data

        streamFinished()
      })

      eventSource.addEventListener('redirect', (e) => {
        return navigate(e.data)
      })
    }
  }, [])

  return (
    <div className='absolute top-0 left-0 w-full'>
      <div className='relative w-9/12 bg-gray-200/90 p-5 rounded-md mx-auto my-20'>
        <div className='absolute top-2 right-2'>
          <button
            className='bg-transparent border border-transparent'
            onClick={close}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='16'
              height='16'
              fill='currentColor'
              className='w-6 h-6 text-gray-700'
              viewBox='0 0 1792 1792'
            >
              <path d='M1490 1322q0 40-28 68l-136 136q-28 28-68 28t-68-28l-294-294-294 294q-28 28-68 28t-68-28l-136-136q-28-28-28-68t28-68l294-294-294-294q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294 294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68l-294 294 294 294q28 28 28 68z'></path>
            </svg>
          </button>
        </div>
        <Headline className='mb-3 flex'>
          <IoSparkles size='1.25rem' color='#FFC300' className='mt-1' />
          <span className='ml-2'>AI Assistent</span>
        </Headline>
        <Formik
          initialValues={{ message: '' }}
          onSubmit={async (values, { setFieldValue }) => {
            setFieldValue('message', '')
            await sendMessage(values.message)
          }}
        >
          <Form>
            <div className='flex mb-2'>
              <Input
                className='grow mr-2'
                name='message'
                placeholder='Spørg mig om hvad som helst i forhold til dit budget'
                autoFocus
              />
              <Button type='submit'>Send</Button>
            </div>
          </Form>
        </Formik>
        <div className='bg-white mt-2 py-2 px-4 rounded-md max-h-70 overflow-auto'>
          <p className='font-medium'>{question}</p>
          {!question && (
            <>
              <p className='font-medium text-gray-600'>
                Hvad kan AI assistenten hjælpe dig med?
              </p>
              <ul className='list-disc space-y-2 ml-5 mt-2 text-gray-600'>
                <li>
                  Give dig informationer omkring budgettering generelt.
                  <br />
                  Prøv f.eks. med{' '}
                  <span className='font-medium italic'>
                    Hvorfor skal jeg have et budget?
                  </span>
                </li>
                <li>
                  Give dig vejledning omkring dit specfikke budget.
                  <br />
                  Prøv f.eks. med{' '}
                  <span className='font-medium italic'>
                    Hvordan kan jeg spare 500,- kr hver måned?
                  </span>
                </li>
                <li>
                  Hjælpe dig med alle funktioner.
                  <br />
                  Prøv f.eks. med{' '}
                  <span className='font-medium italic'>
                    Tilføj Husleje på 15.000 kr hver måned´
                  </span>
                </li>
              </ul>
            </>
          )}
          <div className='mt-2' id='answer' />
          {isLoading && (
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.5em'
              height='1.5em'
              viewBox='0 0 24 24'
              color='lightgray'
            >
              <circle cx='4' cy='12' r='3' fill='currentColor'>
                <animate
                  id='svgSpinners3DotsBounce0'
                  attributeName='cy'
                  begin='0;svgSpinners3DotsBounce1.end+0.25s'
                  calcMode='spline'
                  dur='0.6s'
                  keySplines='.33,.66,.66,1;.33,0,.66,.33'
                  values='12;6;12'
                />
              </circle>
              <circle cx='12' cy='12' r='3' fill='currentColor'>
                <animate
                  attributeName='cy'
                  begin='svgSpinners3DotsBounce0.begin+0.1s'
                  calcMode='spline'
                  dur='0.6s'
                  keySplines='.33,.66,.66,1;.33,0,.66,.33'
                  values='12;6;12'
                />
              </circle>
              <circle cx='20' cy='12' r='3' fill='currentColor'>
                <animate
                  id='svgSpinners3DotsBounce1'
                  attributeName='cy'
                  begin='svgSpinners3DotsBounce0.begin+0.2s'
                  calcMode='spline'
                  dur='0.6s'
                  keySplines='.33,.66,.66,1;.33,0,.66,.33'
                  values='12;6;12'
                />
              </circle>
            </svg>
          )}
          {showError && (
            <p className='text-red-500'>
              Ups! Der skete desværre en fejl. Prøv igen.
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

export default Chat
