import React, { FC } from 'react'

interface Props {
  children: React.ReactNode
  background?: 'white' | 'cyan' | 'blue' | 'dark-blue' | 'red'
}

const Section: FC<Props> = ({ children, background = 'white' }) => {
  return (
    <div className={`bg-theme-${background}`}>
      <div className="px-8 mx-auto max-w-7xl py-5">{children}</div>
    </div>
  )
}

export default Section
