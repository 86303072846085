import Config from '../../../lib/Config'

const BASE_URL = `${Config.API}/ai`

export const chat = async (content: string, budgetId: string | undefined) => {
  const payload = {
    message: content
  } as { [key: string]: string }

  if (budgetId) {
    payload.budget_id = budgetId
  }

  return await fetch(`${BASE_URL}/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`
    },
    body: JSON.stringify(payload),
    credentials: 'include'
  })
}
