import { Form, Formik } from 'formik'
import { FC, useState } from 'react'
import { MdDone, MdModeEditOutline } from 'react-icons/md'
import { formatCurrency } from '../../../../lib/CurrencyFormatter'
import Button from '../../../Forms/Button'
import Input from '../../../Forms/Input'

import * as Yup from 'yup'
type Props = {
  allowance: number
  allowanceUpdated: (allowance: number) => void
  maximumAllowance: number
}
const BalancedAllowanceColumn: FC<Props> = ({
  allowance,
  allowanceUpdated,
  maximumAllowance,
}) => {
  const [isActive, toggleActive] = useState<boolean>(false)

  return (
    <Formik
      initialValues={{
        allowance,
      }}
      validationSchema={Yup.object({
        allowance: Yup.number()
          .min(0, '< 0')
          .max(maximumAllowance, `> ${maximumAllowance}`),
      })}
      onSubmit={(values) => {
        allowanceUpdated(values.allowance)
        toggleActive(false)
      }}
    >
      <Form>
        {isActive && (
          <div className='flex'>
            <Input name='allowance' type='number' />
            <Button size='sm' type='submit' className='ml-1'>
              <MdDone />
            </Button>
          </div>
        )}
        {!isActive && (
          <span
            onClick={() => toggleActive(true)}
            className='flex cursor-pointer'
          >
            {formatCurrency(allowance)}
            <MdModeEditOutline size='1.1rem' />
          </span>
        )}
      </Form>
    </Formik>
  )
}

export default BalancedAllowanceColumn
