import React from 'react'
import Button from '../../Forms/Button'
import { Formik, Form } from 'formik'
import Input from '../../Forms/Input'
import * as Yup from 'yup'
import { Sheet, SheetCategory, Spend } from '../../../lib/Types'
import { MdDelete, MdDone, MdDragHandle } from 'react-icons/md'
import { useDeleteCategory, useUpdateCategory } from '../lib/Hooks/Categories'
import { useSpends } from '../lib/Hooks/Spends'

type Props = {
  sheet: Sheet
  category: SheetCategory
}

const Category = ({ sheet, category }: Props) => {
  const { data: spends } = useSpends(sheet)
  const { mutate: updateCategory, isPending: isUpdateLoading } = useUpdateCategory(sheet)
  const { mutateAsync: deleteCategory, isPending: isDeleteLoading } = useDeleteCategory(sheet)

  const isCategoryUsed = spends?.some((spend: Spend) => spend.category.id === category.id)

  return (
    <div className='draggable'>
      <Formik
        initialValues={{
          name: category.name || '',
          budget: category.budget || undefined
        }}
        validationSchema={Yup.object({
          name: Yup.string().required('Påkrævet')
        })}
        onSubmit={({ name, budget }) => {
          updateCategory({ ...category, name, budget })
        }}
      >
        <Form>
          <div className='flex mt-2 items-center'>
            <MdDragHandle className='draggable-handle mr-2 cursor-pointer' />
            <Input name='name' placeholder="Navn" className='grow mr-2' />
            <Input type='number' name='budget' placeholder="Budget" className='mr-2' />
            <Button
              tooltip={isCategoryUsed ? 'Kategorien kan ikke slettes, da findes udgifter i den.' : undefined}
              disabled={isCategoryUsed}
              type='button'
              loading={isDeleteLoading}
              theme='red'
              className='mr-2'
              onClick={() => {
                if (window.confirm(`Er du sikker på at du vil slette ${category.name}?`)) {
                  deleteCategory(category)
                }
              }}
            >
              <MdDelete />
            </Button>
            <Button
              type='submit'
              loading={isUpdateLoading}
            >
              <MdDone />
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  )
}

export default Category
