import React, { useContext, useEffect, useState } from 'react'
import Text from '../../Elements/Text'
import Category from './Category'
import { useCategories, useUpdateCategory } from '../lib/Hooks/Categories'
import CategoryAdd from './CategoryAdd'
import { ReactSortable } from 'react-sortablejs'
import { SheetContext } from '../../../lib/Context'

const CategoriesForm = () => {
  const { sheet } = useContext(SheetContext)
  const { data: categories } = useCategories(sheet)
  const { mutateAsync: updateCategory } = useUpdateCategory(sheet)
  const [stateCategories, setStateCategories] = useState(categories)

  useEffect(() => {
    setStateCategories(categories)
  }, [categories])

  return (
    <div className='ml-5 mb-5'>
      <Text className='mb-5'>Du kan her redigere de kategorier der skal være mulige at vælge for udgifterne i dit regnskab. Hvis et regnskab kun har én kategori, vil den kategori være valgt som standard, og du vil ikke skulle vælge en kategori, når du tilføjer en udgift.</Text>
      <ReactSortable
        group='categories'
        list={stateCategories}
        setList={async (reorderedCategories, _, { dragging }) => {
          if (!dragging) {
            return
          }

          setStateCategories(reorderedCategories)

          reorderedCategories.forEach((category, index) => {
            if (category.order !== index) {
              category.order = index
              updateCategory(category)
            }
          })
        }}
        dragClass="list-selected"
        ghostClass="list-placeholder"
        draggable=".draggable"
        handle='.draggable-handle'
      >
        {stateCategories!.map(category => (
          <Category
            key={category.id}
            sheet={sheet}
            category={category}
          />
        ))}
      </ReactSortable>
      <CategoryAdd
        sheet={sheet}
      />
    </div>
  )
}

export default CategoriesForm
