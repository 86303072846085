import React, { FC } from 'react'
import LineChart from '../../Charts/LineChart'
import { formatDate, getDateFromKey } from '../../../lib/Dates'
import { Accounting } from '../../../lib/Types'
import { useAccountingBalances } from '../lib/Hooks/Accounting'
import { AccountingType } from '../lib/Enums/AccountingType'

interface Props {
  accounting: Accounting
  showEmptyWarning?: boolean
}

const AccountingDevelopmentChart: FC<Props> = ({ accounting, showEmptyWarning = true }) => {
  const { data: amounts } = useAccountingBalances(accounting)
  const data = [] as { key: number, value: number }[]
  const dataToRender = {} as { [key: string]: number }

  if (!amounts) {
    return null
  }

  Object.keys(amounts).forEach((key) => {
    data.push({ key: parseInt(key, 10), value: amounts[key] })
  })

  // Sort data by key descending.
  data.sort((a, b) => {
    const groupA = a.key.toString().match(/^(\d{4})(.*)/)!
    const groupB = b.key.toString().match(/^(\d{4})(.*)/)!

    const yearA = parseInt(groupA[1], 10)
    const kickerA = parseInt(groupA[2], 10)

    const yearB = parseInt(groupB[1], 10)
    const kickerB = parseInt(groupB[2], 10)

    if (yearA !== yearB) {
      return yearA - yearB
    } else {
      return kickerA - kickerB
    }
  })

  data.forEach((item) => {
    const date = getDateFromKey(accounting, item.key)

    let dateKey: string

    switch (accounting.type) {
    case AccountingType.WEEK:
      dateKey = `uge ${formatDate(date, 'ww / yy')}`
      break
    case AccountingType.MONTH:
      dateKey = `${formatDate(date, 'mmm yy')}`
      break
    case AccountingType.YEAR:
      dateKey = `${formatDate(date, 'yyyy')}`
      break
    }

    dataToRender[dateKey!] = item.value
  })

  return (
    <>
      {(Object.keys(amounts).length === 0) && showEmptyWarning && (
        <p className='italic'>Der er ikke nok data til at vise grafen</p>
      )}
      {(Object.keys(amounts).length > 0) && (
        <LineChart
          data={dataToRender}
          budget={accounting.budget || undefined}
        />
      )}
    </>
  )
}

export default AccountingDevelopmentChart
