import React, { FC, useState } from 'react'
import Headline from '../../Elements/Headline'
import { useSpends, useUpdateSpend } from '../lib/Hooks/Spends'
import { Accounting, Sheet, Spend } from '../../../lib/Types'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { formatDate } from '../../../lib/Dates'
import Text from '../../Elements/Text'

interface Props {
  from: Date,
  to: Date,
  accounting: Accounting
  unpaid?: boolean
}

const Expenses: FC<Props> = ({ from, to, accounting, unpaid = false }) => {
  const { data: expenses } = useSpends(
    accounting,
    { from, to, isExpense: true, nonPaidExpensesOnly: unpaid }
  )

  const sum = (): number => {
    return expenses?.reduce((value: number, current: Spend) => {
      return value + current.amounts.at(-1)!.amount
    }, 0) || 0
  }

  return (
    <>
      <Headline className='mb-4'>Udlæg</Headline>
      {expenses!.length > 0 && (
        <table className='w-full'>
          <thead>
            <tr>
              <th className='text-left'>Udlæg</th>
              <th className='text-left'>Dato</th>
              <th className='text-left'>Udlægshaver</th>
              <th className='text-left'>Beløb</th>
              <th className='text-left'>Er betalt</th>
            </tr>
          </thead>
          <tbody>
            {expenses?.map((expense: Spend) => (
              <tr key={expense.id}>
                <td>{expense.title}</td>
                <td>{formatDate(new Date(expense.accounting_date), 'dd/mm/yy')}</td>
                <td>{expense.expense_name!}</td>
                <td>{formatCurrency(expense.amounts.at(-1)!.amount)}</td>
                <td>
                  <ExpenseItem sheet={accounting} spend={expense} />
                </td>
              </tr>

            ))}
            <tr>
              <td className='pt-2'><strong>I alt</strong></td>
              <td></td>
              <td></td>
              <td><strong>{formatCurrency(sum())}</strong></td>
              <td></td>
            </tr>
          </tbody>
        </table>
      )}
      {expenses!.length === 0 && (
        <Text size='base'>Der findes ingen ubetalte udlæg.</Text>
      )}
    </>
  )
}

interface ExpenseItemProps {
  sheet: Sheet
  spend: Spend
}
const ExpenseItem: FC<ExpenseItemProps> = ({ sheet, spend }) => {
  const [isChecked, toggleChecked] = useState(spend.expense_paid)
  const { mutateAsync: updateSpend } = useUpdateSpend(sheet)

  return (
    <input
      checked={isChecked}
      type="checkbox"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        toggleChecked(event.target.checked)
        const newSpend = structuredClone(spend)
        newSpend.expense_paid = event.target.checked
        updateSpend(newSpend)
      }}
    />
  )
}

export default Expenses
