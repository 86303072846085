import { AccountingType } from '../Components/Sheets/lib/Enums/AccountingType'
import { Accounting } from './Types'

export const formatDateISO8601 = (date: Date): string => {
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()
  return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}T${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`
}

export const formatDate = (date: Date, format = 'mm/dd yyyy h:i'): string => {
  const minutes = date.getMinutes().toString().padStart(2, '0')
  const hour = date.getHours().toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear().toString()
  const shortYear = date.getFullYear().toString().slice(-2)

  return format
    .replace('i', minutes)
    .replace('h', hour)
    .replace('dd', day)
    .replace('ww', getWeek(date).toString())
    .replace('mmm', getMonthNameFromNumber(date.getMonth() + 1, true))
    .replace('mm', month)
    .replace('yyyy', year)
    .replace('yy', shortYear)
}

export const getWeek = function (dateToGetWeekFrom: Date): number {
  const date = new Date(dateToGetWeekFrom.getTime())
  date.setHours(0, 0, 0, 0)
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4)
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  const week =
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    )

  return week
}

export const getWeekYear = function (dateToGetWeekFrom: Date) {
  const date = new Date(dateToGetWeekFrom.getTime())
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7))
  return date.getFullYear()
}

export const monthDiff = (d1: Date, d2: Date): number => {
  let months
  months = (d2.getFullYear() - d1.getFullYear()) * 12
  months -= d1.getMonth()
  months += d2.getMonth()
  return months <= 0 ? 0 : months
}

export const getDateFromWeekAndYear = (week: number, year: number): Date => {
  const date = new Date(year, 0, 1)
  const day = date.getDay()
  const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when year starts
  date.setDate(diff + (week - 1) * 7) // calculate correct date based on week number

  return date
}

export const getDateFromYearAndMonth = (year: number, month: number): Date => {
  const now = new Date()
  return new Date(
    year,
    month - 1,
    1,
    now.getHours(),
    now.getMinutes(),
    now.getSeconds(),
    now.getMilliseconds(),
  )
}

export const getMonthNameFromNumber = (
  month: number,
  shortCode: boolean = false,
): string => {
  switch (month) {
    case 1:
      return shortCode ? 'Jan' : 'Januar'
    case 2:
      return shortCode ? 'Feb' : 'Februar'
    case 3:
      return shortCode ? 'Mar' : 'Marts'
    case 4:
      return shortCode ? 'Apr' : 'April'
    case 5:
      return 'Maj'
    case 6:
      return shortCode ? 'Jun' : 'Juni'
    case 7:
      return shortCode ? 'Jul' : 'Juli'
    case 8:
      return shortCode ? 'Aug' : 'August'
    case 9:
      return shortCode ? 'Sep' : 'September'
    case 10:
      return shortCode ? 'Okt' : 'Oktober'
    case 11:
      return shortCode ? 'Nov' : 'November'
    case 12:
      return shortCode ? 'Dec' : 'December'
  }
  return ''
}

const getDateFromISOWeek = (week: number, year: number) => {
  const date = new Date()

  date.setFullYear(year)
  date.setMonth(0)
  date.setDate(1 + (week - 1) * 7)

  if (date.getDay() <= 4) {
    date.setDate(date.getDate() - date.getDay() + 1)
  } else {
    date.setDate(date.getDate() + 8 - date.getDay())
  }

  return date
}

// Get the date from a group key. See the format for group keys in the
// "getSpendGroupKey" function.
export const getDateFromKey = (accounting: Accounting, key: number): Date => {
  const date = new Date()

  if (accounting!.type === AccountingType.YEAR) {
    date.setFullYear(key)
  } else {
    const match = key.toString().match(/([0-9]{4})([0-9]{1,2})/)

    if (accounting!.type === AccountingType.WEEK) {
      return getDateFromISOWeek(
        parseInt(match![2], 10),
        parseInt(match![1], 10),
      )
    } else if (accounting!.type === AccountingType.MONTH) {
      date.setFullYear(parseInt(match![1], 10))
      date.setMonth(parseInt(match![2], 10) - 1, 1)
    }
  }

  return date
}
