import { useContext, useEffect, useState } from 'react'
import {
  MdArrowDropDown,
  MdArrowRight,
  MdAutoGraph,
  MdOutlineReceiptLong,
  MdPostAdd,
  MdSettings,
} from 'react-icons/md'
import { SidebarContext } from '../../../lib/Context'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import {
  getDateFromKey,
  getMonthNameFromNumber,
  getWeek,
} from '../../../lib/Dates'
import { Sheet, Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Button from '../../Forms/Button'
import Configuration from '../Configuration/Configuration'
import { AccountingType } from '../lib/Enums/AccountingType'
import { AccountingViewMode } from '../lib/Enums/AccountingViewMode'
import {
  useAccounting,
  useAccountingBalance,
  useAccountingBalances,
} from '../lib/Hooks/Accounting'
import { useCategories } from '../lib/Hooks/Categories'
import { useSpends } from '../lib/Hooks/Spends'
import Welcome from '../Welcome'
import './Accounting.css'
import AccountingDevelopment from './AccountingDevelopment'
import Categories from './Categories'
import AccountConfiguration from './Configuration'
import CreateSpend from './CreateSpend'
import Expenses from './Expenses'
import ViewCategories from './ViewCategories'
import ViewMode from './ViewMode'
import ViewSpends from './ViewSpends'

export const summarizeItems = (spends: Spend[]) => {
  let total = 0

  const spendsToSummarize = spends.filter(
    (spend) => !spend.exclude_from_summing && !spend.expense_paid,
  )

  spendsToSummarize.forEach((spend) => {
    total += spend.amounts.at(-1)!.amount
  })

  return total
}

const summarizeExpenses = (spends: Spend[]) => {
  let total = 0

  const spendsToSummarize = spends.filter(
    (spend) => !!spend.is_expense && !spend.expense_paid,
  )

  spendsToSummarize.forEach((spend) => {
    total += spend.amounts.at(-1)!.amount
  })

  return total
}

const AccountingComponent = () => {
  const { data: accounting } = useAccounting()
  const { data: spends } = useSpends(accounting as Sheet)
  const { data: categories } = useCategories(accounting!)
  const { data: balances } = useAccountingBalances(accounting!)
  const { data: balance } = useAccountingBalance(accounting!)

  // Depending on the accounting type and the accounting date, the spends are
  // grouped by different keys.
  const getSpendGroupKey = (accountingDate: Date): number => {
    const year = accountingDate.getFullYear()

    if (accounting!.type === AccountingType.YEAR) {
      return year
    } else if (accounting!.type === AccountingType.MONTH) {
      const month = accountingDate.getMonth() + 1
      return parseInt(`${year}${month}`, 10)
    } else if (accounting!.type === AccountingType.WEEK) {
      const week = getWeek(accountingDate)
      return parseInt(`${year}${week}`, 10)
    }
    return 0
  }

  const [showAddSpendModal, toggleAddSpendModal] = useState(false)
  const [addSpendCategory, setAddSpendCategory] = useState<number | null>(null)
  const [accountingDateForNewSpend, setAccountingDateForNewSpend] =
    useState<Date | null>()
  const [spendToEdit, setSpendToEdit] = useState<Spend | null>(null)
  const { showSidebar } = useContext(SidebarContext)
  const [groups, setGroups] = useState<Map<number, Spend[]>>(new Map())
  const [expandedGroups, setExpandedGroups] = useState<number[]>([
    getSpendGroupKey(new Date()),
  ])
  const [expandedCategories, setExpandedCategories] = useState<number[]>([])

  // Build the maps of spends to render.
  useEffect(() => {
    const spendGroups = new Map<number, Spend[]>()

    // Iterate all items to split them into the right months.
    spends!.forEach((spend) => {
      const key = getSpendGroupKey(new Date(spend.accounting_date))

      if (!spendGroups.has(key)) {
        spendGroups.set(key, [])
      }

      spendGroups.get(key)?.push(spend)
    })

    // Find the group with the lowest key.
    const firstGroup = Array.from(spendGroups.keys()).reduce((lowest, key) => {
      return Math.min(lowest, key)
    }, Infinity)

    // Make sure to render every month or week from the earliest group hithero.
    if (firstGroup !== Infinity) {
      // The end key will always be the key generated from the current date.
      const endKey = getSpendGroupKey(new Date())

      const startDate = getDateFromKey(accounting!, firstGroup)
      let currentKey = getSpendGroupKey(startDate)

      while (currentKey < endKey) {
        const currentDate = getDateFromKey(accounting!, currentKey)

        if (accounting!.type === AccountingType.WEEK) {
          currentDate.setDate(currentDate.getDate() + 7)
        } else if (accounting!.type === AccountingType.MONTH) {
          currentDate.setMonth(currentDate.getMonth() + 1)
        } else if (accounting!.type === AccountingType.YEAR) {
          currentDate.setFullYear(currentDate.getFullYear() + 1)
        }

        currentKey = getSpendGroupKey(currentDate)

        if (!spendGroups.has(currentKey)) {
          spendGroups.set(currentKey, [])
        }
      }
    }

    // If no first group exists, it's an empty accounting sheet. Add one group.
    if (
      firstGroup === Infinity ||
      !spendGroups.has(getSpendGroupKey(new Date()))
    ) {
      spendGroups.set(getSpendGroupKey(new Date()), [])
    }

    // Order the groups by ascending key
    const orderedSpendGroups = new Map(
      [...spendGroups.entries()].sort((a, b) => {
        const groupA = a[0].toString().match(/^(\d{4})(.*)/)!
        const groupB = b[0].toString().match(/^(\d{4})(.*)/)!

        const yearA = parseInt(groupA[1], 10)
        const kickerA = parseInt(groupA[2], 10)

        const yearB = parseInt(groupB[1], 10)
        const kickerB = parseInt(groupB[2], 10)

        if (yearA !== yearB) {
          return yearA - yearB
        } else {
          return kickerA - kickerB
        }
      }),
    )

    setGroups(orderedSpendGroups)
  }, [spends])

  // Reset the states for the add / edit modal.
  const hideModal = () => {
    setSpendToEdit(null)
    setAccountingDateForNewSpend(null)
    toggleAddSpendModal(false)
    setAddSpendCategory(null)
  }

  const getStartDateFromKey = (key: number): Date => {
    const date = getDateFromKey(accounting!, key)
    let newDate = new Date(date)

    if (accounting!.type === AccountingType.WEEK) {
      // Find the first day of the week from the date.
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
      newDate.setDate(diff)
    } else if (accounting!.type === AccountingType.MONTH) {
      newDate.setDate(1)
    } else if (accounting!.type === AccountingType.YEAR) {
      newDate.setMonth(0)
      newDate.setDate(1)
    } else {
      newDate = new Date(accounting!.created_date)
    }

    return newDate
  }

  const getEndDateFromKey = (key: number): Date => {
    const date = getDateFromKey(accounting!, key)
    let newDate = new Date(date)

    if (accounting!.type === AccountingType.WEEK) {
      // Find the last day of the week from the date.
      const day = date.getDay()
      const diff = date.getDate() - day + (day === 0 ? -6 : 1) // adjust when day is Sunday
      newDate.setDate(diff + 6) // Add 6 to get the last day of the week
    } else if (accounting!.type === AccountingType.MONTH) {
      newDate.setMonth(date.getMonth() + 1) // Go to the next month
      newDate.setDate(0) // Go back one day to the last day of the previous month
    } else if (accounting!.type === AccountingType.YEAR) {
      newDate.setFullYear(date.getFullYear() + 1) // Go to the next year
      newDate.setMonth(0) // Go to January
      newDate.setDate(0) // Go back one day to the last day of the previous year
    } else {
      newDate = new Date(accounting!.created_date)
    }

    return newDate
  }

  // Use the color to indicate the balance for the group.
  const getBalanceColor = (items: Spend[]): string => {
    if (!accounting!.budget) return 'text-black'

    const balanceForGroup = accounting!.budget - summarizeItems(items)

    if (balanceForGroup < 0) {
      return 'text-red-500'
    } else if (balanceForGroup < 300) {
      return 'text-yellow-600'
    }
    return 'text-green-700'
  }

  return (
    <>
      <Welcome
        sheet={accounting!}
        show={spends!.length === 0 && !accounting!.is_paid}
      />
      <div className='flex flex-col md:flex-row justify-between mb-3'>
        <div className='mb-4 md:mb-0'>
          <Headline>{accounting!.name || 'Regnskab'}</Headline>
          {accounting!.description && (
            <p className={'italic '}>{accounting!.description}</p>
          )}
        </div>
        <div className='flex'>
          <Button
            theme='gray'
            className='mr-2'
            onClick={() =>
              showSidebar(
                <Expenses
                  from={new Date(accounting!.created_date)}
                  to={new Date()}
                  accounting={accounting!}
                  unpaid={true}
                />,
              )
            }
          >
            <MdOutlineReceiptLong size='1.5rem' />
          </Button>
          {accounting!.type !== AccountingType.REGULAR && (
            <Button
              theme='gray'
              className='mr-2'
              onClick={() =>
                showSidebar(<AccountingDevelopment accounting={accounting!} />)
              }
            >
              <MdAutoGraph size='1.5rem' />
            </Button>
          )}
          <ViewMode accounting={accounting!} />
          <Button
            theme='gray'
            className='ml-2'
            onClick={() =>
              showSidebar(
                <Configuration sheet={accounting!}>
                  <AccountConfiguration />
                </Configuration>,
              )
            }
          >
            <MdSettings size='1.5rem' />
          </Button>
        </div>
      </div>

      {accounting!.dedicated_account && (
        <p className='mb-3'>
          Nuværende saldo: {<strong>{formatCurrency(balance!)}</strong>}
        </p>
      )}
      {summarizeExpenses(spends!) > 0 && (
        <p className='-mt-2 mb-3'>
          Udlæg i alt:{' '}
          <strong>{formatCurrency(summarizeExpenses(spends!))}</strong>
        </p>
      )}
      <CreateSpend
        acccountingDate={accountingDateForNewSpend}
        spend={spendToEdit}
        show={showAddSpendModal}
        hideModal={hideModal}
        accounting={accounting!}
        category={addSpendCategory}
      />
      <div className='overflow-auto'>
        <div className='table min-w-full'>
          {[...groups.entries()].reverse().map(([key, spends]) => (
            <div key={key}>
              <table className='min-w-full'>
                <thead className={'bg-gray-300 border border-gray-400'}>
                  <tr>
                    <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                      <div className='flex items-center'>
                        <div
                          className='flex cursor-pointer'
                          onClick={() => {
                            // Check if the key is in the expanded groups. If it is, remove it, if not, add it.
                            const expandedGroupIndex =
                              expandedGroups.indexOf(key)
                            const newExpandedGroups = [...expandedGroups]

                            if (expandedGroupIndex !== -1) {
                              newExpandedGroups.splice(expandedGroupIndex, 1)
                            } else {
                              newExpandedGroups.push(key)
                            }

                            setExpandedGroups(newExpandedGroups)
                          }}
                        >
                          {accounting!.type !== AccountingType.REGULAR && (
                            <div>
                              {expandedGroups.indexOf(key) !== -1 && (
                                <MdArrowDropDown size='1.25rem' />
                              )}
                              {expandedGroups.indexOf(key) === -1 && (
                                <MdArrowRight size='1.25rem' />
                              )}
                            </div>
                          )}
                          {accounting!.type === AccountingType.YEAR && (
                            <span className='grow mr-10 font-bold'>{key}</span>
                          )}
                          {accounting!.type === AccountingType.MONTH && (
                            <span className='grow mr-10 font-bold'>
                              {`${getMonthNameFromNumber(getDateFromKey(accounting!, key).getMonth() + 1)} ${getDateFromKey(accounting!, key).getFullYear()}`}
                            </span>
                          )}
                          {accounting!.type === AccountingType.WEEK && (
                            <span className='grow mr-10 font-bold'>
                              Uge{' '}
                              {`${getWeek(getDateFromKey(accounting!, key))} / ${getDateFromKey(accounting!, key).getFullYear()}`}
                            </span>
                          )}
                        </div>
                        <div className='grow flex'>
                          <p className={`mr-10 ${getBalanceColor(spends)}`}>
                            {`Balance: ${formatCurrency(summarizeItems(spends))} (${formatCurrency(balances![key] || 0)}) ${accounting!.budget > 0 ? `/ ${formatCurrency(accounting!.budget)}` : ''}`}
                          </p>
                          {summarizeExpenses(spends) > 0 && (
                            <p>
                              Udlæg: {formatCurrency(summarizeExpenses(spends))}
                            </p>
                          )}
                        </div>
                        <Button
                          onClick={() =>
                            showSidebar(
                              <Expenses
                                from={getStartDateFromKey(key)}
                                to={getEndDateFromKey(key)}
                                accounting={accounting!}
                              />,
                            )
                          }
                          theme='blue'
                          size='sm'
                          className='mr-2'
                        >
                          <div className='flex items-center'>
                            <MdOutlineReceiptLong className='mr-2' />
                            Vis udlæg
                          </div>
                        </Button>
                        <Button
                          onClick={() => {
                            setAccountingDateForNewSpend(
                              getDateFromKey(accounting!, key as number),
                            )
                            toggleAddSpendModal(true)
                          }}
                          theme='blue'
                          size='sm'
                        >
                          <div className='flex items-center'>
                            <MdPostAdd className='mr-2' />
                            Ny postering
                          </div>
                        </Button>
                      </div>
                    </th>
                  </tr>
                </thead>
              </table>
              {expandedGroups.indexOf(key) !== -1 && (
                <>
                  {accounting!.view_mode === AccountingViewMode.CATEGORIES && (
                    <ViewCategories
                      spends={spends}
                      editSpend={(spend: Spend) => {
                        setSpendToEdit(spend)
                        toggleAddSpendModal(true)
                      }}
                      addSpend={(category: number) => {
                        setAccountingDateForNewSpend(
                          getDateFromKey(accounting!, key as number),
                        )
                        setAddSpendCategory(category)
                        toggleAddSpendModal(true)
                      }}
                    />
                  )}
                  {accounting!.view_mode === AccountingViewMode.SPENDS && (
                    <>
                      <ViewSpends
                        spends={spends!}
                        editSpend={(spend: Spend) => {
                          setSpendToEdit(spend)
                          toggleAddSpendModal(true)
                        }}
                      />
                      {categories!.length > 1 && (
                        <Categories
                          isExpanded={expandedCategories.indexOf(key) !== -1}
                          toggleExpanded={(expand) => {
                            if (expand) {
                              setExpandedCategories([
                                ...expandedCategories,
                                key,
                              ])
                            } else {
                              setExpandedCategories(
                                expandedCategories.filter(
                                  (expandedKey) => expandedKey !== key,
                                ),
                              )
                            }
                          }}
                          spends={spends}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default AccountingComponent
