import { FC, useState } from 'react'
import { usePaymentForSheet } from '../../lib/Hooks/Payment'
import { Sheet } from '../../lib/Types'
import Headline from '../Elements/Headline'
import { Modal } from '../Elements/Modal'
import Text from '../Elements/Text'
import Button from '../Forms/Button'
import { SheetType } from './lib/Enums/SheetType'

interface Props {
  sheet: Sheet
  show: boolean
}

const Welcome: FC<Props> = ({ sheet, show }) => {
  const [showModal, setShowModal] = useState(show)
  const [showPayment, togglePayment] = useState(false)
  const { mutateAsync: mutatePayment, isPending: isPaymentLoading } =
    usePaymentForSheet()

  const sendPayment = async () => {
    await mutatePayment(sheet)
    togglePayment(true)
  }

  return (
    <>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        {!showPayment && (
          <>
            <Headline>
              Velkommen til dit nye{' '}
              {sheet.sheet_type === SheetType.ACCOUNTING
                ? 'regnskab'
                : 'budget'}{' '}
              🧾
            </Headline>
            {sheet.sheet_type === SheetType.ACCOUNTING && (
              <Text className='mt-3'>
                Det er nemt at komme i gang med at bogføre. Du kan oprette en ny
                postering ved at klikke på knappen "Ny postering" i højre
                hjørne.
              </Text>
            )}
            {sheet.sheet_type === SheetType.BUDGET && (
              <>
                <Text className='mt-3 mb-3'>
                  Se videoen herunder hvordan du hurtigt og nemt kommer i gang
                  med dit nye budget. Du kan også læse mere om hvordan du gør{' '}
                  <a
                    className='underline'
                    href='https://privat-regnskab.dk/budget'
                    target='_blank'
                    rel='noreferrer'
                  >
                    her
                  </a>
                  .
                </Text>
                {sheet.sheet_type === SheetType.BUDGET && (
                  <video controls width='100%'>
                    <source
                      src='https://privat-regnskab.dk/videos/opret-budget.mp4'
                      type='video/mp4'
                    />
                  </video>
                )}
              </>
            )}
            <Text className='mt-3'>
              Du har 14 dages gratis adgang til{' '}
              {sheet.sheet_type === SheetType.ACCOUNTING
                ? 'regnskabet'
                : 'budgettet'}
              , hvorefter du skal betale{' '}
              {sheet.sheet_type === SheetType.ACCOUNTING ? '29' : '49'},- kr
              årligt for at fortsætte. Hvis du har spørgsmål, er du altid
              velkommen til at kontakte os på kontakt@privat-regnskab.dk.
            </Text>
            {sheet.sheet_type === SheetType.ACCOUNTING && (
              <Text className='mt-3'>
                Du kan læse mere om regnskabet og funktionerne{' '}
                <a
                  className='underline'
                  href='https://privat-regnskab.dk/regnskab'
                  target='_blank'
                  rel='noreferrer'
                >
                  her
                </a>
                .
              </Text>
            )}
            <div className='flex mt-6'>
              <Button className='mr-5' onClick={() => setShowModal(false)}>
                Lad mig komme igang
              </Button>
              <Button
                theme='white'
                onClick={sendPayment}
                loading={isPaymentLoading}
              >
                Betal {sheet.sheet_type === SheetType.ACCOUNTING ? '29' : '49'}
                ,- kr nu
              </Button>
            </div>
          </>
        )}
        {showPayment && (
          <>
            <Headline>Tak for din støtte 🙏🏼</Headline>
            <Text className='mt-3'>
              Tak for din støtte til Privat Regnskab, det sætter vi stor pris
              på!
            </Text>
            <Text className='mt-3'>
              Du vil om kort tid modtage et link til betaling af{' '}
              {sheet.sheet_type === SheetType.ACCOUNTING
                ? 'regnskabet'
                : 'budgettet'}
              . Når du har gennemført betalingen, vil du have adgang til{' '}
              {sheet.sheet_type === SheetType.ACCOUNTING
                ? 'regnskabet'
                : 'budgettet'}{' '}
              i et år.
            </Text>
            <Text className='mt-3'>
              Hvis du har spørgsmål, er du altid velkommen til at kontakte os på
              kontakt@privat-regnskab.dk.
            </Text>
            <Button className='mt-6' onClick={() => setShowModal(false)}>
              Lad mig komme igang
            </Button>
          </>
        )}
      </Modal>
    </>
  )
}

export default Welcome
