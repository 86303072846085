import Config from '../../../../lib/Config'
import { Accounting } from '../../../../lib/Types'
import { SheetType } from '../Enums/SheetType'

const getHeaders = () => ({
  Authorization: `Bearer ${localStorage.getItem('token')}`
})


export const createAccounting = async (data: Partial<Accounting>): Promise<Accounting> => {
  const response = await fetch(`${Config.API}/accountings/`, {
    method: 'POST',
    headers: {
      ...getHeaders(),
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })

  const accounting = await response.json()

  return {
    ...accounting,
    sheet_type: SheetType.ACCOUNTING
  }
}

export const getAccountings = async (): Promise<Accounting[]> => {
  const response = await fetch(`${Config.API}/accountings/`, {
    headers: getHeaders()
  })

  if (response.status !== 200) {
    throw response.status
  }

  const accountings = await response.json()

  return accountings.map((accounting: Accounting) => {
    return {
      ...accounting,
      sheet_type: SheetType.ACCOUNTING
    }
  })
}

export const getAccounting = async (accountingId: number): Promise<Accounting> => {
  const response = await fetch(`${Config.API}/accountings/${accountingId}/`, {
    headers: getHeaders()
  })

  if (response.status !== 200) {
    throw response.status
  }

  const accounting = await response.json()

  return {
    ...accounting,
    sheet_type: SheetType.ACCOUNTING
  }
}

export const getAccountingBalance = async (accountingId: number): Promise<any> => {
  const response = await fetch(`${Config.API}/accountings/${accountingId}/balance/`, {
    headers: getHeaders()
  })

  if (response.status !== 200) {
    throw response.status
  }

  return response.json()
}

export const getAccountingBalances = async (accountingId: number): Promise<any> => {
  const response = await fetch(`${Config.API}/accountings/${accountingId}/balances/`, {
    headers: getHeaders()
  })

  if (response.status !== 200) {
    throw response.status
  }

  return response.json()
}
