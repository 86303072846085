import React from 'react'
import { Navigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useAuthenticatedUser } from '../lib/Hooks/User'

const ProtectedRoute = ({ children }) => {
  const { data: authenticatedUser } = useAuthenticatedUser()

  if (!authenticatedUser) {
    return <Navigate to='/login' replace />
  }

  return children
}

ProtectedRoute.propTypes = {
  children: PropTypes.object
}

export default ProtectedRoute
