import React, { FC, Suspense } from 'react'
import Headline from '../../Elements/Headline'
import LoadingSpinner from '../../Elements/LoadingSpinner'
import AccountingDevelopmentChart from './AccountingDevelopmentChart'
import { Accounting } from '../../../lib/Types'

interface Props {
  accounting: Accounting
}

const AccountingDevelopment: FC<Props> = ({ accounting }) => (
  <>
    <Headline>Udvikling</Headline>
    <p className='mt-2 mb-5'>Her kan du se udviklingen af forbruget for regnskabet igennem de tidligere perioder.</p>
    <Suspense fallback={<LoadingSpinner />}>
      <AccountingDevelopmentChart accounting={accounting} />
    </Suspense>
  </>
)

export default AccountingDevelopment
