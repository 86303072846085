import React, { FC } from 'react'
import LineChart from '../../Charts/LineChart'
import { formatDate } from '../../../lib/Dates'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { Budget } from '../../../lib/Types'
import { useBudgetAmounts } from '../lib/Hooks/Budget'

interface Props {
  budget: Budget
  showEmptyWarning?: boolean
}

const BudgetDevelopmentChart: FC<Props> = ({ budget, showEmptyWarning = true }) => {
  const budgetToUse = budget
  const { data: budgetAmounts } = useBudgetAmounts(budgetToUse)

  if (!budgetAmounts) {
    return null
  }

  const data = budgetAmounts!.reduce((obj, spend) => {
    obj[formatDate(new Date(spend.created_date), 'yyyy-mm-dd')] = spend.amount
    return obj
  }, {} as Record<string, number>)

  const tooltipCallback = (dataIndex: number) => {
    // For each spend show the line.
    const tooltip = budgetAmounts[dataIndex].spends.map((spend) => (
      `${spend.title}: ${formatCurrency(spend.amount)} kr.`
    ))

    tooltip.push('')

    const total = budgetAmounts[dataIndex].spends.reduce((total, spend) => total + spend.amount, 0)
    // Add the calculated total of all the spends to the tooltip.
    tooltip.push(`Total: ${formatCurrency(total)} kr.`)

    return tooltip
  }

  return (
    <>
      {(budgetAmounts.length === 0) && showEmptyWarning && (
        <p className='italic'>Der er ikke nok data til at vise grafen</p>
      )}
      {(budgetAmounts.length > 0) && (
        <LineChart
          data={data}
          tooltipCallback={tooltipCallback}
        />
      )}
    </>
  )
}

export default BudgetDevelopmentChart
