import React, { useContext, useState } from 'react'
import Button from '../../Forms/Button'
import { Formik, Form } from 'formik'
import Input from '../../Forms/Input'
import * as Yup from 'yup'
import Headline from '../../Elements/Headline'
import Text from '../../Elements/Text'
import { getImperativName, getSingularName } from './lib/Naming'
import { SheetContext } from '../../../lib/Context'
import { MdArrowDropDown, MdArrowRight } from 'react-icons/md'
import { useInviteUser, useUpdateSheet } from '../lib/Hooks/Sheet'

const Share = () => {
  const { sheet } = useContext(SheetContext)
  const { mutate: updateSheet, isPending: isSaving } = useUpdateSheet()
  const { mutate: inviteUserToSheet, isPending: isInviting } = useInviteUser()
  const [isExpanded, toggleExpanded] = useState(false)

  const uninviteUser = async () => {
    if (!window.confirm(`Dette vil slette invitation for ${sheet.invites}. Er du sikker?`)) {
      return
    }

    const newSheet = structuredClone(sheet)
    newSheet.invites = undefined

    updateSheet(newSheet)
  }

  const inviteUser = async (email: string) => {
    if (!window.confirm(`Er du sikker på at du vil dele ${getImperativName(sheet.sheet_type)} med ${email}?`)) {
      return
    }


    const newSheet = structuredClone(sheet)
    newSheet.invites = email

    inviteUserToSheet(newSheet)
  }

  const removeUser = async () => {
    if (!window.confirm(`Er du sikker på at du vil fjerne deling fra ${sheet.user.name || sheet.user.email}?`)) {
      return
    }

    const newSheet = structuredClone(sheet)
    newSheet.user = undefined

    updateSheet(newSheet)
  }

  return (
    <>
      <Headline className='mb-2'>
        <div className='flex items-center cursor-pointer' onClick={() => toggleExpanded(!isExpanded)}>
          {!isExpanded && <MdArrowRight size='1.25rem' />}
          {isExpanded && <MdArrowDropDown size='1.25rem' />}
          Deling
        </div>
      </Headline>
      {isExpanded && (
        <div className='ml-5 mb-5'>
          {sheet.user && (
            <>
              <Text className='mb-4'>Du har delt {getImperativName(sheet.sheet_type)} med <strong>{sheet.user.name || sheet.user.email}</strong>. Stop delingen ved at trykke på knappen herunder.</Text>
              <Button
                theme='red'
                className='w-full'
                onClick={removeUser}
                loading={isSaving}
              >
                Fjern deling
              </Button>
            </>
          )}
          {!sheet.user && (
            <>
              {sheet.invites && (
                <Text className='mt-4'>
                  Du har inviteret <strong>{sheet.invites}</strong> til {getImperativName(sheet.sheet_type)}.<br />
                  Tryk <u className='cursor-pointer' onClick={uninviteUser}>her</u> for at annullere invitationen.
                </Text>
              )}
              {!sheet.invites && (

                <Formik
                  initialValues={{
                    email: ''
                  }}
                  validationSchema={Yup.object({
                    email: Yup.string().email('Ugyldig e-mail').required('Påkrævet')
                  })}
                  onSubmit={(values) => inviteUser(values.email)}
                >
                  <Form>
                    <Text>
                      Et {getSingularName(sheet.sheet_type)} skal oftes deles, f.eks. med en partner. Angiv en e-mail adresse her, for at dele {getImperativName(sheet.sheet_type)}.
                    </Text>
                    <div className='flex mt-4'>
                      <Input
                        placeholder="Indtast e-mail adresse"
                        type="email"
                        name="email"
                        className='flex-1'
                      />
                      <Button
                        type="submit"
                        loading={isInviting}
                        className='ml-2'
                      >
                        Inviter
                      </Button>
                    </div>
                  </Form>
                </Formik>
              )}
            </>
          )}
        </div>
      )}
    </>
  )
}

export default Share
