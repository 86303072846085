import React, { FC } from 'react'

interface Props {
    children: React.ReactNode
    className?: string
    size?: 'sm' | 'base' | 'lg'
}

const Text: FC<Props> = ({ children, className, size = 'sm' }) => {
  return (
    <div className={`self-center text-${size} font-light text-gray-600 sm:text-${size} ${className}`}>{children}</div>
  )
}

export default Text
