import React, { FC } from 'react'
import { MdSync } from 'react-icons/md'

type Props = {
  className?: string
}

const LoadingSpinner: FC<Props> = ({ className }) => {
  return (
    <MdSync className={`animate-spin ${className}`} size={30} />
  )
}

export default LoadingSpinner
