import { allowanceForUser } from '../../../../lib/Calculator'
import { UserType } from '../../../../lib/Enums/UserType'
import Headline from '../../../Elements/Headline'
import Text from '../../../Elements/Text'
import { useBudget } from '../../lib/Hooks/Budget'
import { useSpends } from '../../lib/Hooks/Spends'

const BalancedAllowanceHelpText = () => {
  const { data: budget } = useBudget()
  const { data: spends } = useSpends(budget!)

  const allowanceForAuthor = allowanceForUser(
    spends!,
    UserType.PRIMARY,
    budget!.author.data.salary!,
  )
  const allowanceForOtherUser = allowanceForUser(
    spends!,
    UserType.SECONDARY,
    budget!.user!.data.salary!,
  )

  if (allowanceForAuthor === allowanceForOtherUser) {
    return null
  }

  return (
    <>
      <Headline className='mb-2' size='base'>
        Balanceret rådighedsbeløb
      </Headline>
      <Text className='mb-2'>
        Da I ikke har et lige stort rådighedsbeløb kan I vælge at balancere
        jeres økonomi. Det betyder at den ene betaler en større del af de fælles
        udgifter, således I opnår et lige stort rådighedsbeløb. Se tabellen
        ovenover for udregningen af balanceringen.
      </Text>
      <Text className='mb-5'>
        I kan også vælge at justere det balancerede rådighedsbeløb, hvis den ene
        skal have et større eller mindre rådighedsbeløb end den anden.
      </Text>
    </>
  )
}

export default BalancedAllowanceHelpText
