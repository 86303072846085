import { useContext, useEffect, useRef, useState } from 'react'
import {
  MdAccountBalanceWallet,
  MdAutoGraph,
  MdDownload,
  MdInsertChart,
  MdPostAdd,
  MdSettings,
} from 'react-icons/md'
import { sum, sumForUser } from '../../../lib/Calculator'
import { SidebarContext, SpendContext } from '../../../lib/Context'
import { formatCurrency } from '../../../lib/CurrencyFormatter'
import { UserType } from '../../../lib/Enums/UserType'
import { SheetCategory, Spend } from '../../../lib/Types'
import Headline from '../../Elements/Headline'
import Button from '../../Forms/Button'
import Configuration from '../Configuration/Configuration'
import * as BudgetAPI from '../lib/API/BudgetAPI'
import { SpendFrequency } from '../lib/Enums/SpendFrequency'
import { useBudget } from '../lib/Hooks/Budget'
import { useCategories } from '../lib/Hooks/Categories'
import { useSpends, useUpdateSpend } from '../lib/Hooks/Spends'
import Payment from '../Payment'
import Welcome from '../Welcome'
import AddSpend from './AddSpend'
import Allowance from './Allowance/Allowance'
import BalanceOverview from './BalanceOverview'
import './Budget.css'
import BudgetDevelopment from './BudgetDevelopment'
import Items from './Items'

declare global {
  interface Array<T> {
    clone(): Array<T>
  }
}

const BudgetComponent = () => {
  const urlParams = new URLSearchParams(window.location.search)
  const { data: budget } = useBudget()
  const { data: categories } = useCategories(budget!)
  const { data: items } = useSpends(budget!)
  const { mutateAsync: updateSpend } = useUpdateSpend(budget!)
  const [showAdd, toggleShowAdd] = useState<boolean>(false)
  const [isScrolled, setIsScrolled] = useState<boolean>()
  const [tableWidth, setTableWidth] = useState(0)
  const { showSidebar } = useContext(SidebarContext)
  const refSticky = useRef<HTMLTableSectionElement>(null)
  const [showDownloadPaymentModal, setShowDownloadPaymentModal] =
    useState(false)

  const checkScrollPosition = () => {
    const offset = window.scrollY
    if (offset > 95) {
      setTableWidth(refSticky.current?.offsetWidth || 0)
      setIsScrolled(true)
    } else {
      setIsScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', checkScrollPosition)
    return () => {
      window.removeEventListener('scroll', checkScrollPosition)
    }
  }, [])

  const startDownload = () => {
    if (!budget?.is_paid) {
      setShowDownloadPaymentModal(true)
    } else {
      BudgetAPI.downloadBudget(budget!)
    }
  }

  /**
   * Split the items into categories, by moving items with a parent into the parent items children array.
   */
  const categorizeItems = (category: SheetCategory): Spend[] => {
    const categorizedItems: { [k: number]: Spend } = {}

    const itemsInCategory = items!.filter((item) => {
      return item.category.id === category.id
    })

    // Start by building the list of parent items
    itemsInCategory.forEach((item) => {
      if (!item.parent_id) {
        categorizedItems[item.id] = item
        categorizedItems[item.id].children = []
      }
    })

    // Add all children to the their parents.
    itemsInCategory.forEach((item) => {
      if (item.parent_id && categorizedItems[item.parent_id]) {
        categorizedItems[item.parent_id].children.push(item)
      }
    })

    // Make sure the items in the new array are ordered by their item.order property
    const orderedItemsInCategory = Object.values(categorizedItems).sort(
      (a, b) => a.order - b.order,
    )

    // Order the children of the parent items
    orderedItemsInCategory.forEach((item) => {
      item.children.sort((a, b) => a.order - b.order)
    })

    return orderedItemsInCategory
  }

  /**
   * Iterate a set of items to see if the order they are represented are identical to the order from the DB.
   *
   * @param {array} itemsToSave
   *
   * @returns {bool}
   *   Whether anything was saved or not
   */
  const saveOrder = async (itemsToSave: Spend[]): Promise<boolean> => {
    const itemsToUpdate: { [k: number]: Spend } = {}

    // Iterate all items to update the order
    itemsToSave.forEach((item, index) => {
      if (item.order !== index) {
        item.order = index
        itemsToUpdate[item.id] = item
      }
    })

    const itemsToUpdateArr: Spend[] = Object.values(itemsToUpdate)

    await Promise.all(itemsToUpdateArr.map((item) => updateSpend(item)))

    return itemsToUpdateArr.length > 0
  }

  if (!budget) {
    return <p>No budget</p>
  }

  return (
    <>
      <Welcome
        sheet={budget}
        show={items?.length === 0 && !budget.is_paid && !urlParams.get('hide')}
      />
      <Payment
        title='Du skal betale for dit budget, før du kan hente det'
        show={showDownloadPaymentModal}
        sheet={budget}
      />
      <SpendContext.Provider value={{ saveOrder }}>
        <AddSpend toggleHide={() => toggleShowAdd(false)} show={showAdd} />
        <Headline>
          <div className='flex flex-col md:flex-row justify-between  mb-2'>
            <span className='mb-3 md:mb-0'>
              {budget.name || 'Budget'}
              {budget.description && (
                <p className='italic text-base'>{budget.description}</p>
              )}
            </span>
            <div className='flex justify-between'>
              <Button
                theme='gray'
                tooltip='Indstillinger'
                onClick={() => showSidebar(<Configuration sheet={budget} />)}
              >
                <MdSettings size='1.5rem' />
              </Button>
              <Button
                theme='gray'
                className='ml-2'
                tooltip='Balanceoverblik'
                onClick={() => showSidebar(<BalanceOverview spends={items!} />)}
              >
                <MdInsertChart size='1.5rem' />
              </Button>
              <Button
                theme='gray'
                className='ml-2'
                tooltip='Budgetudvikling'
                onClick={() =>
                  showSidebar(<BudgetDevelopment budget={budget!} />)
                }
              >
                <MdAutoGraph size='1.5rem' />
              </Button>
              <Button
                theme='gray'
                className='ml-2'
                tooltip='Rådighedsbeløbsudregning'
                onClick={() => showSidebar(<Allowance items={items!} />)}
              >
                <MdAccountBalanceWallet size='1.5rem' />
              </Button>

              <Button
                theme='gray'
                className='ml-2'
                tooltip='Download budget'
                onClick={startDownload}
              >
                <MdDownload size='1.5rem' />
              </Button>
              <Button onClick={() => toggleShowAdd(true)} className='ml-2'>
                <div className='flex items-center'>
                  <MdPostAdd className='mr-2' />
                  Tilføj udgift
                </div>
              </Button>
            </div>
          </div>
        </Headline>

        <div className='flex flex-col'>
          <div className='overflow-x-auto sm:mx-0.5 lg:mx-0.5'>
            <div className='py-2 inline-block w-full'>
              <div className='min-w-fit'>
                <table
                  className={`table-budget ${budget.user ? 'col-5' : ''} ${isScrolled ? 'fixed top-[64px]' : 'min-w-full'}`}
                  style={{ width: isScrolled ? `${tableWidth + 1}px` : 'auto' }}
                >
                  <thead
                    ref={refSticky}
                    className={'bg-gray-300 border border-gray-300'}
                  >
                    <tr>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        Titel
                      </th>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        År
                      </th>
                      <th
                        scope='col'
                        className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                      >
                        Måned
                      </th>
                      {budget.user && (
                        <>
                          <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                          >
                            {budget.author.name ||
                              budget.author.email.split('@')[0]}
                          </th>

                          <th
                            scope='col'
                            className='text-sm font-medium text-gray-900 px-6 py-4 text-left'
                          >
                            {budget.user.name ||
                              budget.user.email.split('@')[0]}
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                </table>
                <div className={`${isScrolled ? 'mt-[53px]' : ''}`}>
                  {categories &&
                    categories.map((category) => (
                      <Items
                        key={category.id}
                        category={category}
                        spends={categorizeItems(category)}
                      />
                    ))}
                </div>
                <table
                  className={`min-w-full table-budget ${budget.user ? 'col-5' : ''}`}
                >
                  <tbody>
                    <tr className='bg-gray-300 border-b border-x border-gray-300'>
                      <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                        I alt
                      </th>
                      <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                        {formatCurrency(sum(items!, SpendFrequency.YEARLY))}
                      </th>
                      <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                        {formatCurrency(sum(items!, SpendFrequency.MONTHLY))}
                      </th>
                      {budget.user && (
                        <>
                          <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                            {formatCurrency(
                              sumForUser(items!, UserType.PRIMARY),
                            )}
                          </th>
                          <th className='text-sm font-medium text-gray-900 px-6 py-4 text-left'>
                            {formatCurrency(
                              sumForUser(items!, UserType.SECONDARY),
                            )}
                          </th>
                        </>
                      )}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </SpendContext.Provider>
    </>
  )
}

export default BudgetComponent
