import React, { FC } from 'react'
import Button from '../../Forms/Button'
import { Accounting } from '../../../lib/Types'
import { MdFormatListBulleted, MdGridView } from 'react-icons/md'

import { AccountingViewMode } from '../lib/Enums/AccountingViewMode'
import { useUpdateSheet } from '../lib/Hooks/Sheet'

interface Props {
  accounting: Accounting
}

const ViewMode: FC<Props> = ({ accounting }) => {
  const { mutate: updateSheet } = useUpdateSheet()
  return (
    <Button
      theme='gray'
      onClick={() => {
        const newViewMode = accounting.view_mode === AccountingViewMode.CATEGORIES
          ? AccountingViewMode.SPENDS
          : AccountingViewMode.CATEGORIES

        const newAccounting = structuredClone(accounting)
        newAccounting.view_mode = newViewMode

        updateSheet(newAccounting)
      }}
    >
      {accounting.view_mode === 'categories' && <MdFormatListBulleted size='1.5rem' />}
      {accounting.view_mode === 'spends' && <MdGridView size='1.5rem' />}
    </Button>

  )
}

export default ViewMode
